<template>
  <div
    v-if="heading"
    class="app-card-title">
    <h3>{{ heading }}</h3>
    <template v-if="!withTabs">
      <div
        v-if="fullScreen || reloadable || closeable"
        class="app-contextual-link">
        <v-menu
          transition="scale-transition"
          origin="right top"
          bottom
          left
        >
          <v-btn
            slot="activator"
            class="ma-0"
            icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-list class="app-card-action">
            <v-list-tile
              v-if="fullScreen"
              @click="$emit('onCollapse')">
              <i class="zmdi zmdi-fullscreen primary--text mr-2 fs-14"/>
              <span>{{ $t("message.fullScreen") }}</span>
            </v-list-tile>
            <v-list-tile
              v-if="reloadable"
              @click="$emit('onReload')">
              <i class="zmdi zmdi-refresh success--text mr-2 fs-14"/>
              <span>{{ $t("message.reload") }}</span>
            </v-list-tile>
            <v-list-tile
              v-if="closeable"
              @click="$emit('onClose')">
              <i class="zmdi zmdi-close mr-2 error--text fs-14"/>
              <span>{{ $t("message.close") }}</span>
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-else>
      <div class="app-contextual-link">
        <ul class="custom-tab-wrap list-inline">
          <template v-for="(tab, key) in tabs">
            <li
              :key="key"
              @click="onChangeTab(key)">
              <a
                :class="[{'primary white--text': activeTab === key}]"
                href="javascript:void(0)"
                class="fs-12 fw-normal px-2 py-1 rounded mx-1" >{{ tab }}</a>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    'heading',
    'closeable',
    'reloadable',
    'fullScreen',
    'withTabs',
    'tabs',
    'onCollapse',
    'onReload',
    'onClose',
    'onChangeTabCallback'
  ],
  data () {
    return {
      activeTab: 0
    }
  },
  methods: {
    onChangeTab (value) {
      this.activeTab = value
      this.$emit('onChangeTabCallback', value)
    }
  }
}
</script>
