<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    :content-class="customClass"
    persistent>
    <v-card
      v-if="show"
      class="elevation-0">
      <v-card-title>
        <div class="full-width p-relative">
          <div v-if="loader" class="ride-card-disabled"></div>
          <div class="header-french-blue text-xs-left mb-2">{{ title }}</div>
          <slot/>
        </div>
      </v-card-title>
      <v-card-actions class="pa-3 justify-center topindex">
        <v-layout>
          <v-flex sm3>
            <div class="d-flex align-center justify-end mr-auto">
              <v-btn
                v-if="!loading && isRidePage && readonly"
                :loading="loading"
                color="primary"
                outline
                large
                @click.native="confirm()"
              >Save</v-btn>
            </div>
          </v-flex>
          <v-flex sm6>
            <div class="d-flex align-center justify-center mx-auto">
              <v-btn
                v-if="!readonly"
                :loading="loading"
                color="primary"
                large
                @click.native="confirm()">{{ edit ? 'Save' : 'Add' }}</v-btn>

              <v-btn
                v-if="!loading"
                color="warning"
                outline
                large
                @click.native="close(isRidePage)"
              >{{ isRidePage ? 'Close & Save' : 'Cancel' }}</v-btn>
            </div>
          </v-flex>
          <v-flex sm3>
            <div class="d-flex align-center justify-end ml-auto">
              <v-btn
                v-if="isRidePage"
                color="error"
                outline
                large
                @click.native="close(false)"
              >Annuleer</v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: { default: false, type: Boolean },
    isRidePage: { default: false, type: Boolean },
    title: { default: null, type: String },
    description: { default: null, type: String },
    loading: { default: false, type: Boolean },
    loader: { default: false, type: Boolean },
    edit: { default: true, type: Boolean },
    readonly: { default: false, type: Boolean },
    maxWidth: { default: '500px', type: String },
    customClass: { default: '', type: String },
    isConfirmCancel: { default: false, type: Boolean }
  },
  methods: {
    close (isRideSave = true) {
      if (this.isConfirmCancel) {
        if (confirm('Are you sure you want to close?')) {
          if (!this.isRidePage) {
            this.$emit('update:show', false)
          }
          this.$emit(isRideSave ? 'dialogCloseSave' : 'dialogClose')
        }
      } else {
        this.$emit('update:show', false)
        this.$emit(isRideSave ? 'dialogCloseSave' : 'dialogClose')
      }
    },
    confirm () {
      this.$emit('dialogConfirm', true)
    }
  }
}
</script>

<style lang="scss">
  .ride-card-disabled {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.65);
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ride-card-dialog {
    position: relative;
  }

  .topindex {
    z-index: 1000;
  }
</style>
