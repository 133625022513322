const TEXT_FIELD_MAX_LENGTH = 250

const REVIEW_SUBJECT_APP = 'app'
const REVIEW_SUBJECT_DRIVER = 'driver'
const REVIEW_SUBJECT_PASSENGER = 'passenger'
const REVIEW_SUBJECT_PROCESS = 'process'
const REVIEW_SUBJECT_RIDE = 'ride'

const INSURE_PASSENGER_ASK = 'ask'
const INSURE_PASSENGER_NEVER = 'never'
const INSURE_PASSENGER_ALWAYS = 'always'

const FRONT_PASSENGER = 'front_passenger'
const REAR_BEHIND_DRIVER = 'rear_behind_driver'
const REAR_BEHIND_PASSENGER = 'rear_behind_passenger'

const DRIVER_DRESS_SUIT = 'suit'
const DRIVER_DRESS_CASUAL = 'casual'

const FAQ = 'faq'
const PAGE = 'page'

const EN = 'EN'
const NL = 'NL'

const WEB_NOTIFICATION_TYPES = ['persistent', 'single', 'flash']
const REVIEW_SUBJECTS = [REVIEW_SUBJECT_APP, REVIEW_SUBJECT_DRIVER, REVIEW_SUBJECT_PASSENGER, REVIEW_SUBJECT_PROCESS, REVIEW_SUBJECT_RIDE]
const INSURE_PASSENGERS = [INSURE_PASSENGER_ASK, INSURE_PASSENGER_NEVER, INSURE_PASSENGER_ALWAYS]
const PASSENGER_CAR_POSITIONS = [FRONT_PASSENGER, REAR_BEHIND_DRIVER, REAR_BEHIND_PASSENGER]
const DRIVER_DRESSES = [DRIVER_DRESS_SUIT, DRIVER_DRESS_CASUAL]
const POST_TYPES = [FAQ, PAGE]
const LANGUAGE_TYPES = [EN, NL]

const SAVING_STATUS = {
  default: 'default',
  wait: 'wait',
  saved: 'saved',
  error: 'error'
}

const BRAND = {
  DRIVE_ME: 'driveme',
  RENTABOB: 'rentabob',
  EDRIVERS: 'edrivers'
}

const DAYS = {
  maandag: 'monday',
  dinsdag: 'tuesday',
  woensdag: 'wednesday',
  donderdag: 'thursday',
  vrijdag: 'friday',
  zaterdag: 'saturday',
  zondag: 'sunday'
}

const DISCOUNT_TYPE = {
  percents: 'percents',
  amount: 'amount'
}

const isInsuranceEnabledForPassenger = (passenger) => passenger.insure === INSURE_PASSENGER_ASK || passenger.insure === INSURE_PASSENGER_ALWAYS

export {
  SAVING_STATUS,
  TEXT_FIELD_MAX_LENGTH,
  REVIEW_SUBJECT_APP,
  REVIEW_SUBJECT_DRIVER,
  REVIEW_SUBJECT_PASSENGER,
  REVIEW_SUBJECT_PROCESS,
  REVIEW_SUBJECT_RIDE,
  WEB_NOTIFICATION_TYPES,
  REVIEW_SUBJECTS,
  INSURE_PASSENGERS,
  INSURE_PASSENGER_ASK,
  INSURE_PASSENGER_NEVER,
  INSURE_PASSENGER_ALWAYS,
  POST_TYPES,
  LANGUAGE_TYPES,
  PASSENGER_CAR_POSITIONS,
  DRIVER_DRESSES,
  BRAND,
  DAYS,
  DISCOUNT_TYPE,
  isInsuranceEnabledForPassenger
}
