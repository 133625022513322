// import { store } from '../../store/index'
import axios from 'axios'
import { isTokenDataValid, isTokenExpired, clearToken } from './tokenHelper'
import { BRAND } from 'Constants/common'
import AppConfig from 'Constants/AppConfig'

import drivemeAdminRoutes from '../router/driveme_admin_routes'
import drivemeSupportRoutes from '../router/driveme_support_routes'
import drivemeFinanceRoutes from '../router/driveme_finance_routes'
import { getEnvVersion } from './helpers'

const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_SUPPORT = 'ROLE_SUPPORT'
const ROLE_FINANCE = 'ROLE_FINANCE'

export const roles = [
  ROLE_ADMIN,
  ROLE_SUPPORT,
  ROLE_FINANCE
]

export const AUTHORIZATION_TOKEN_ERRORS = [
  'authorization.token_not_found',
  'authorization.token_expired'
]

const BRAND_DATA = {
  [BRAND.DRIVE_ME]: {
    [ROLE_ADMIN]: {
      title: 'DriveMe',
      routes: drivemeAdminRoutes
    },
    [ROLE_SUPPORT]: {
      title: 'DriveMe - Support',
      routes: drivemeSupportRoutes
    },
    [ROLE_FINANCE]: {
      title: 'DriveMe - Finance',
      routes: drivemeFinanceRoutes
    }
  }
}

const clearEmptyBodyData = (data) => {
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      if (typeof data[key] === 'object' && data[key] !== null) {
        clearEmptyBodyData(data[key])
      } else if (value === undefined) {
        data[key] = value
      } else {
        data[key] = value !== false && value !== 0 ? value || null : value
      }
    }
  }
}

export default {
  data () {
    return {
      aborter: {}
    }
  },
  methods: {
    async request (method, url, data, onSuccess = () => {}, loader, onError = () => {}, hideError) {
      return new Promise(async (resolve, reject) => {
        const urlAbort = url.split('?')[0]
        const isAborted = data && data.data && data.data.isAborted === false ? data.data.isAborted : true
        data && data.data && delete data.data.isAborted
        this._setLoading(true)
        if (loader != null) loader(true)
        let impersonatedUser = false
        if (impersonatedUser) { url = url + '?_switch_user=' + impersonatedUser }

        const dmSecretaryId = localStorage.getItem('dmSecretaryId')

        const secretaryHeaderPart =
          this.$store.getters.getUser &&
          this.$store.getters.getUser.secretaryOf &&
          this.$store.getters.getUser.secretaryOf.length &&
          dmSecretaryId &&
          parseInt(dmSecretaryId) !== parseInt(this.$store.getters.getUser.id)
            ? {
              'Http-X-Switch-User': dmSecretaryId
            }
            : {}
        if (this.aborter[urlAbort] && isAborted) {
          this.aborter[urlAbort]()
        }

        if (data && data.data) {
          clearEmptyBodyData(data.data)
        }

        const requestOptions = {
          method,
          url,
          ...data,
          cancelToken: new axios.CancelToken((aborter) => {
            this.aborter[urlAbort] = aborter
          }),
          headers: data && data.headers
            ? {
              ...data.headers,
              ...secretaryHeaderPart
            }
            : {
              ...secretaryHeaderPart
            }
        }

        await this.axios.request(requestOptions)
          .then((data) => {
            this.aborter[urlAbort] = null
            onSuccess(data)
            resolve(data)
          })
          .catch(async (err) => {
            if (!err.response) {
              console.log(err)
              onError(err)
              reject(err)
              return
            }

            const data = err.response.data

            if (data.type === 'authorization.token_invalid') {
              await this.killToken()
              clearToken()
            }

            if (data.type === 'authorization.token_expired') {
              // TODO need to refresh token and replace it from /refresh api

              if (window.location.href.indexOf('/signup') > -1) {
                await this.killToken()
                clearToken()

                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            }

            let text = data.title
            if (data.details) {
              text += ' ' + data.details
            }

            if ((err.response.status !== 401 && window.location.href.indexOf('/session/login') === -1) || !hideError) {
              if (roles.includes(getEnvVersion().siteVersion)) {
                if (data.violations) {
                  data.violations.forEach((error) => {
                    this.$notify({
                      group: 'topRight',
                      type: 'error',
                      text: `${error.property}, ${error.message}`
                    })
                  })
                } else if (data.original) {
                  this.$notify({
                    group: 'topRight',
                    type: 'error',
                    text: data.original
                  })
                } else if (Array.isArray(data)) {
                  for (let i = 0; i < data.length; i++) {
                    const element = data[i]
                    for (let j = 0; j < element.errors.length; j++) {
                      const itemError = element.errors[j]
                      this.$notify({
                        group: 'topRight',
                        type: 'error',
                        text: itemError.message
                      })
                    }
                  }
                } else {
                  this.$notify({
                    group: 'topRight',
                    type: 'error',
                    text: JSON.stringify(data)
                  })
                }
              } else {
                this.$notify({
                  group: 'topRight',
                  type: 'error',
                  text: text
                })
              }
            }

            onError(data)
            reject(data)
          })
          .finally(() => {
            this._setLoading(false)
            if (loader != null) loader(false)
          })
      })
    },
    async sendFileRequest (url, file, onSuccess, loader) {
      this._setLoading(true)
      if (loader != null) loader(true)
      await this.axios.request({ method: 'POST', url, file, headers: { 'Content-Type': file.type } })
        .then((data) => {
          onSuccess(data)
        })
        .catch((err) => {
          if (err.response == null) console.log(err)

          const data = err.response.data
          let text = data.title
          if (data.details) {
            text += ' ' + data.details
          }

          this.$notify({
            group: 'topRight',
            type: 'error',
            text: text
          })
        })
        .finally(() => {
          this._setLoading(false)
          if (loader != null) loader(false)
        })
    },
    requestNoAuth (method, url, data, onSuccess, onError) {
      this._setLoading(true)
      this.axiosNoAuth.request({ method, url, ...data })
        .then((data) => {
          onSuccess(data)
        })
        .catch((err) => {
          if (err.response == null) console.log(err)

          if (err.response.status === 409) {
            // TODO ?
            if (err.response.data.type === 'user.email.duplicate') {
              this.$router.push('/session/login')
            }

            if (onError) {
              onError(err.response.data)
            } else {
              this.$notify({
                group: 'topRight',
                type: 'error',
                text: err.response.data.violations[0].message
              })
            }
          } else {
            const data = err.response.data
            let text = data.title
            if (data.details) {
              text += ' ' + data.details
            }

            this.$notify({
              group: 'topRight',
              type: 'error',
              text: text
            })
          }
        })
        .finally(() => {
          this._setLoading(false)
        })
    },
    authorization (login, password, onSuccess, loader) {
      this._setLoading(true)
      if (loader != null) loader(true)

      const config = {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        withCredentials: true
      }

      const api = axios.create(config)

      api.request({
        url: `${this.$store.getters.config.apiUrl}/token`,
        method: 'post',
        data: {
          username: login,
          password: password
        }
      })
        .then((data) => {
          // REVERT REDIRECT TO ADMIN
          // if (data.data.user && data.data.user.roles.includes('ROLE_ADMIN') && this.$store.getters.getEnv.envVersion.siteVersion === 'ROLE_FINANCE') {
          //   window.location.href = window.location.href.replace('finance', 'admin')
          //   return
          // }

          // storeToken(data.data)
          this.constructRequests()
          onSuccess(data.data)
        })
        .catch(({ response }) => {
          const data = response.data
          let text = data.title
          if (data.details) {
            text += ' ' + data.details
          }
          this.$notify({
            group: 'topRight',
            type: 'error',
            text: text
          })
          if (this.$refs.alert !== undefined) this.$refs.alert.setAlert(text, 'error', 3100)
        })
        .finally(() => {
          this._setLoading(false)
          if (loader != null) loader(false)
        })
    },
    setLoadingHandler (handler) {
      if (typeof handler !== 'function') {
        throw new Error('Expecting callback in setLoadingHandler')
      }

      this.loadingHandler = handler
    },
    preloadWidget () {

    },
    _setLoading (isLoading) {
      this.loadingHandler && this.loadingHandler(isLoading)
    },
    _handleRefresh (axios) {
      if (window.location.href.indexOf('/signup') === -1) {
        axios.interceptors.response.use(response => response, (error) => {
          const originalRequest = error.config

          if (error.response && error.response.status === 401) {
            if (
              error.response.data &&
              AUTHORIZATION_TOKEN_ERRORS.includes(error.response.data.type)
            ) {
              return Promise.resolve(this._doRefreshToken(originalRequest))
            } else {
              localStorage.removeItem('isAuth')
              localStorage.removeItem('user')
              this.$router.push('/session/login')
            }
          }

          return Promise.reject(error)
        })

        axios.interceptors.request.use(request => {
          const isRefreshRequest = request.url.search(`/token/refresh$`) !== -1

          if (!isRefreshRequest && isTokenDataValid() && isTokenExpired()) {
            console.log('The token should be refreshed')
          }

          return request
        })
      }
    },
    _doRefreshToken (originalRequest) {
      return this.axios.post('/token/refresh', {}).then(() => {
        return this.axios.request(originalRequest)
      })
    },
    createTokenInstance () {
      let http = axios.create({
        baseURL: this.baseUrl,
        withCredentials: true
      })
      this._handleRefresh(http)

      return http
    },
    killToken () {
      localStorage.removeItem('isAuth')
      localStorage.removeItem('user')
      return this.axios.post('/token/kill')
    },
    constructRequests () {
      this.baseUrl = this.$store.getters.config.apiUrl
      this.axios = this.createTokenInstance()
      this.axiosNoAuth = this.createTokenInstance('noAuth')
    },
    switchToRightEnv (role) {
      let roleName = ''
      switch (role) {
        case 'ROLE_ADMIN': roleName = 'admin'; break
        case 'ROLE_FINANCE': roleName = 'admin'; break
        case 'ROLE_SUPPORT': roleName = 'admin'; break
      }
      window.location = 'https://' + roleName + this.$store.getters.getEnv.envVersion.domains[this.$store.getters.getEnv.env]
    },
    authorizeUser (data) {
      this.$store.dispatch('loginUser', { data })
      this.$store.dispatch('setUser', data)
      if (!data.roles.includes(ROLE_SUPPORT) && !data.roles.includes(ROLE_FINANCE) && !data.roles.includes(ROLE_ADMIN) && !data.roles.includes('ROLE_SIGN_UP')) {
        this.$notify({
          group: 'topRight',
          type: 'error',
          text: `Er lijkt een probleem te zijn met het account. Neem contact op met support@driveme.nl of bel met 0850443733.`
        })

        this.killToken()
        clearToken()
      } else {
        // switch (this.$store.getters.getEnv.envVersion.siteVersion) {
        //   case ROLE_ADMIN:
        //     if (data.roles.includes(ROLE_ADMIN)) {
        //       this.$store.dispatch('setUserType', ROLE_ADMIN); break
        //     } else {
        //       this.switchToRightEnv(data.roles[0])
        //     }
        //     break
        //   case ROLE_FINANCE:
        //     if (data.roles.includes(ROLE_FINANCE)) {
        //       this.$store.dispatch('setUserType', ROLE_FINANCE); break
        //     } else {
        //       this.switchToRightEnv(data.roles[0])
        //     }
        //     break
        //   case ROLE_SUPPORT:
        //     if (data.roles.includes(ROLE_SUPPORT)) {
        //       this.$store.dispatch('setUserType', ROLE_SUPPORT); break
        //     } else {
        //       this.switchToRightEnv(data.roles[0])
        //     }
        //     break
        // }

        if (data.roles.includes(ROLE_ADMIN)) {
          this.$store.dispatch('setUserType', ROLE_ADMIN)
        } else if (data.roles.includes(ROLE_FINANCE)){
          this.$store.dispatch('setUserType', ROLE_FINANCE)
        } else if (data.roles.includes(ROLE_SUPPORT)) {
          this.$store.dispatch('setUserType', ROLE_SUPPORT)
        } else {
          this.switchToRightEnv(data.roles[0])
        }

        let currentRoute = {}
        switch (this.$store.getters.userType) {
          case ROLE_ADMIN: {
            this.$store.dispatch('setMenus', this.$store.getters.adminMenus)
            currentRoute = BRAND_DATA[AppConfig.brand][ROLE_ADMIN].routes
            this.gFunc.setTitle(BRAND_DATA[AppConfig.brand][ROLE_ADMIN].title)
            break
          }
          case ROLE_SUPPORT: {
            this.$store.dispatch('setMenus', this.$store.getters.supportMenus)
            currentRoute = BRAND_DATA[AppConfig.brand][ROLE_SUPPORT].routes
            this.gFunc.setTitle(BRAND_DATA[AppConfig.brand][ROLE_SUPPORT].title)
            break
          }
          case ROLE_FINANCE: {
            this.$store.dispatch('setMenus', this.$store.getters.financeMenus)
            currentRoute = BRAND_DATA[AppConfig.brand][ROLE_FINANCE].routes
            this.gFunc.setTitle(BRAND_DATA[AppConfig.brand][ROLE_FINANCE].title)
            break
          }
          default: this.$router.push('/session/login'); break
        }

        this.$router.addRoutes(currentRoute)

        this.$store.dispatch('setFullLoader', false)

        return currentRoute
      }
    }
  },
  computed: {
  },
  beforeMount () {
    this.constructRequests()
    this.preloadWidget()
  }
}
