/**
 * Sidebar Module
 */

import { adminMenus, financeMenus, supportMenus } from './data.js'

const state = {
  adminMenus,
  financeMenus,
  supportMenus
}

const getters = {
  adminMenus: state => {
    return state.adminMenus
  },
  financeMenus: state => {
    return state.financeMenus
  },
  supportMenus: state => {
    return state.supportMenus
  }
}

const actions = {
  setActiveMenuGroup (context, payload) {
    context.commit('setActiveMenuGroupHandler', payload)
  }
}

const mutations = {
  setActiveMenuGroupHandler (state, router) {
    let fullPath = ''
    if (router.pathURL) {
      fullPath = router.pathURL
    } else {
      fullPath = router.history.current.fullPath
    }
    let path = fullPath.split('/')
    let matchedPath = '/' + path[2] + '/' + path[3]
    for (const category in state.adminMenus) {
      for (const menuGroup in state.adminMenus[category]) {
        if (state.adminMenus[category][menuGroup].items !== null) {
          for (const matched in state.adminMenus[category][menuGroup].items) {
            if (String(state.adminMenus[category][menuGroup].items[matched].path) === String(matchedPath) || String(state.adminMenus[category][menuGroup].items[matched].path) === String(fullPath)) {
              state.adminMenus[category][menuGroup].active = true
            }
          }
        }
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
