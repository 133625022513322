const oneSignalInit = (oneSignalConfig, appConfig = {}) => {
  if (oneSignalConfig && oneSignalConfig.appId) {
    window.OneSignal = window.OneSignal || []

    window.OneSignal.push(async function () {
      window.OneSignal.init({
        appId: oneSignalConfig.appId,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
        allowLocalhostAsSecureOrigin: true
      })

      // Web only - Gets the value stored locally on the user's browser.
      const externalUserId = await window.OneSignal.getExternalUserId()

      if (!externalUserId || (String(externalUserId) !== String(oneSignalConfig.externalUserI))) {
        window.OneSignal.setExternalUserId(String(oneSignalConfig.externalUserId), oneSignalConfig.externalUserIdAuthHash)
      }

      if (appConfig.ENV !== 'PROD') {
        window.OneSignal.log.setLevel('trace')
      }

      // TODO
      // window.OneSignal.addListenerForNotificationOpened(
      //   (notificationData) => {
      //     console.log('Received NotificationOpened:', notificationData)
      //   }
      // )
    })
  }
}

export {
  oneSignalInit
}
