import Vue from 'vue'
import Vuex from 'vuex'

// modules
import auth from './modules/auth'
import chat from './modules/chat'
import settings from './modules/settings'
import ecommerce from './modules/ecommerce'
import mail from './modules/mail'
import sidebarAdmin from './modules/sidebar_admin'
import sidebasePassenger from './modules/sidebar_passenger'
import sidebarDriver from './modules/sidebar_driver'
import faq from './modules/faq'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    chat,
    settings,
    ecommerce,
    mail,
    sidebarAdmin,
    sidebasePassenger,
    sidebarDriver,
    faq
  }
})
