/**
 * Auth Module
 */
import Vue from 'vue'
import firebase from '@firebase/app'
import Nprogress from 'nprogress'
import router from '../../../router'
import { clearToken } from '../../../helpers/tokenHelper'

import {
  userTypes
} from './data'

const state = {
  user: '',
  isUserSigninWithAuth0: Boolean(localStorage.getItem('isUserSigninWithAuth0')),
  userType: null,
  userTypes, // all user types,
  env: '',
  envVersion: ''
}

// getters
const getters = {
  getUser: state => {
    return state.user
  },
  userType: state => {
    return state.userType
  },
  isUserSigninWithAuth0: state => {
    return state.isUserSigninWithAuth0
  },
  getEnv: state => {
    return { env: state.env, envVersion: state.envVersion }
  }
}

// actions
const actions = {
  setEnv (context, payload) {
    state.env = payload.env
    state.envVersion = payload.envVersion
  },
  setUser (context, payload) {
    state.user = payload
  },
  setUserType (context, payload) {
    state.userType = (payload != null ? payload : null)
  },
  signinUserInFirebase (context, payload) {
    const { user } = payload
    context.commit('loginUser')
    setTimeout(() => {
      Nprogress.done()
      context.commit('loginUserSuccess', user)
    }, 500)
  },
  loginUser (context, payload) {
    context.commit('loginUserSuccess', payload)
  },
  logoutUserFromFirebase (context) {
    Nprogress.start()
    setTimeout(() => {
      Nprogress.done()
      context.commit('logoutUser')
    }, 500)
  },
  logoutUserFromFirebaseWithoutRedirect (context) {
    Nprogress.start()
    setTimeout(() => {
      Nprogress.done()
      context.commit('logoutUser', true)
    }, 500)
  },
  signupUserInFirebase (context, payload) {
    let { userDetail } = payload
    context.commit('signUpUser')
    firebase.auth().createUserWithEmailAndPassword(userDetail.email, userDetail.password)
      .then(() => {
        Nprogress.done()
        setTimeout(() => {
          context.commit('signUpUserSuccess', userDetail)
        }, 500)
      })
      .catch(error => {
        context.commit('signUpUserFailure', error)
      })
  },
  signInUserWithAuth0 (context, payload) {
    context.commit('signInUserWithAuth0Success', payload)
  },
  signOutUserFromAuth0 (context) {
    context.commit('signOutUserFromAuth0Success')
  }
}

// mutations
const mutations = {
  loginUser (state) {
    // Nprogress.start()
  },
  loginUserSuccess (state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('isAuth', true)
    state.isUserSigninWithAuth0 = false
  },
  loginUserFailure (state, error) {
    Nprogress.done()
    Vue.notify({
      group: 'topRight',
      type: 'error',
      text: error.message
    })
  },
  logoutUser (state, withoutRedirect) {
    state.user = null
    localStorage.removeItem('isAuth')
    localStorage.removeItem('user')
    clearToken()

    if (!withoutRedirect) {
      router.push('/session/login')
    }
  },
  signUpUser (state) {
    Nprogress.start()
  },
  signUpUserSuccess (state, user) {
    state.user = localStorage.setItem('user', user)
    localStorage.setItem('isAuth', true)
    router.push('/default/dashboard/ecommerce')
    Vue.notify({
      group: 'topRight',
      type: 'success',
      text: 'Account Created!'
    })
  },
  signUpUserFailure (state, error) {
    Nprogress.done()
    Vue.notify({
      group: 'topRight',
      type: 'error',
      text: error.message
    })
  },
  signInUserWithAuth0Success (state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('isAuth', true)
    state.isUserSigninWithAuth0 = true
  },
  signOutUserFromAuth0Success (state) {
    state.user = null
    localStorage.removeItem('isAuth')
    localStorage.removeItem('user')
  },
  setAddressBusiness (state, data) {
    state.user.passenger.addressBusiness = data
  },
  setAddressPrivate (state, data) {
    state.user.passenger.addressPrivate = data
  },
  setPromoCode (state, data) {
    state.user.passenger.reference = data
  },
  setCompanyName (state, data) {
    state.user.companyName = data
  },
  setInvoiceType (state, data) {
    state.user.passenger.invoiceType = data
  },
  setStopIndex (state, data) {
    state.user.stopIndex = data
  },
  setIsHistoryBack (state, data) {
    state.user.isHistoryBack = data
  },
  setUser (state, data) {
    state.user = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
