export const cart = [
  {
    id: 0,
    productImg: '/static/img/product2.png',
    name: '3DR - Solo Smart Rechargeable Battery - Black',
    price: 9,
    quantity: 5,
    total: 45
  },
  {
    id: 1,
    productImg: '/static/img/product3.png',
    name: 'JÄRA Lamp shade, white',
    price: 15,
    quantity: 2,
    total: 30
  },
  {
    id: 2,
    productImg: '/static/img/product4.png',
    name: 'LEGITIM-Chopping board, white',
    price: 23,
    quantity: 1,
    total: 23
  },
  {
    id: 3,
    productImg: '/static/img/product5.png',
    name: 'Sour cream and onion potato chips',
    price: 21,
    quantity: 4,
    total: 84
  }
]

export const creditCard = [
  {
    id: 0,
    last4Digit: '************8549',
    cvv: 465,
    cardHolderName: 'John Brown'
  },
  {
    id: 1,
    last4Digit: '************6526',
    cvv: 123,
    cardHolderName: 'Jennifer Mernada'
  },
  {
    id: 2,
    last4Digit: '************9645',
    cvv: 789,
    cardHolderName: 'Johnson'
  }
]
