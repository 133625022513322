import Full from 'Container/Full'

// dashboard components
const RideOverview = () => import('../views/admin/ride_overview/index.vue')
const CrudUser = () => import('../views/admin/crud/CrudUser.vue')
const CrudPassenger = () => import('../views/admin/crud/CrudPassenger.vue')
const CrudSecretary = () => import('../views/admin/crud/CrudSecretary.vue')
const ApproveChanges = () => import('../views/admin/tools/ApproveChanges.vue')
const CrudEditPassenger = () => import('../views/admin/crud/CrudEditPages/CrudEditPassenger.vue')
const CrudWebNotification = () => import('../views/admin/crud/CrudWebNotification.vue')
const CrudEditWebNotification = () => import('../views/admin/crud/CrudEditPages/CrudEditWebNotification.vue')
const CrudReview = () => import('../views/admin/crud/CrudReview.vue')
const CrudEditReview = () => import('../views/admin/crud/CrudEditPages/CrudEditReview.vue')
const CrudEditUser = () => import('../views/admin/crud/CrudEditPages/CrudEditUser.vue')
const CrudEditSecretary = () => import('../views/admin/crud/CrudEditPages/CrudEditSecretary.vue')

export default [
  {
    path: '/',
    component: Full,
    redirect: '/dashboard/ride-overview',
    children: [
      {
        path: '/dashboard/ride-overview',
        component: RideOverview,
        meta: {
          requiresAuth: true,
          title: 'Rides',
          breadcrumb: null
        }
      },
      {
        path: '/tables/users',
        component: CrudUser,
        meta: {
          requiresAuth: true,
          title: 'Users',
          breadcrumb: null
        }
      },
      {
        path: '/tables/users/:id',
        component: CrudEditUser,
        meta: {
          requiresAuth: true,
          title: 'User',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers',
        component: CrudPassenger,
        meta: {
          requiresAuth: true,
          title: 'Customers',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers/:id',
        component: CrudEditPassenger,
        meta: {
          requiresAuth: true,
          title: 'Customer',
          breadcrumb: null
        }
      },
      {
        path: '/tables/secretaries',
        component: CrudSecretary,
        meta: {
          requiresAuth: true,
          title: 'Secretaries',
          breadcrumb: null
        }
      },
      {
        path: '/tables/secretaries/:id',
        component: CrudEditSecretary,
        meta: {
          requiresAuth: true,
          title: 'Secretary',
          breadcrumb: null
        }
      },
      {
        path: '/approve-changes',
        component: ApproveChanges,
        meta: {
          requiresAuth: true,
          title: 'Approve Changes',
          breadcrumb: null
        }
      },
      {
        path: '/tables/web-notifications',
        component: CrudWebNotification,
        meta: {
          requiresAuth: true,
          title: 'Web Notifications',
          breadcrumb: null
        }
      },
      {
        path: '/tables/web-notifications/:id',
        component: CrudEditWebNotification,
        meta: {
          requiresAuth: true,
          title: 'Web Notification',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reviews',
        component: CrudReview,
        meta: {
          requiresAuth: true,
          title: 'Reviews',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reviews/:id',
        component: CrudEditReview,
        meta: {
          requiresAuth: true,
          title: 'Review',
          breadcrumb: null
        }
      },
      {
        path: '*',
        name: 'arides',
        component: RideOverview,
        redirect: '/dashboard/ride-overview'
      }
    ]
  }
]
