<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="264px"
      transition="dialog-transition"

    >
      <v-card>
        <v-card-title>
          <v-spacer/>
          <close-button
            color="blue"
            @close="dialog = false"/>
        </v-card-title>

        <v-card-text class="px-0 hidden-overflow pb-0">
          <div class="row-layout justify-space-between icons-row">
            <div class="fake-icon mx-1" />
            <div class="fake-icon mx-1" />

            <v-img
              :src="appIcon"
              :class="`mx-1 middle-icon ${isRentABobApp ? 'rab-app-icon' : ''}`"
              contain />

            <div class="fake-icon mx-1" />
            <div class="fake-icon mx-1" />
          </div>

          <div class="pa-4">
            <div class="black-22 text-xs-center lh-26"><b>{{ descTexts.header }}</b></div>
            <div class="black-14 text-xs-center mt-3">{{ descTexts.body }}</div>
          </div>
        </v-card-text>
        <v-card-actions class="bg-grey">
          <div
            class="text-xs-center black-12 full-width mb-2"
            style="line-height: 23px;">
            Klik hieronder op <span class="ml-1"> <img
              src="../../assets/icons/ios-share-icon.svg"
              class="share-icon"> </span> <br>
            en daarna op ‘zet op beginscherm’
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CloseButton from '../../components/Buttons/CloseButton.vue'
import AppConfig from 'Constants/AppConfig'
import { BRAND } from '../../constants/common'

export default {
  components: { CloseButton },
  data () {
    return {
      dialog: false,
      driverTexts: {
        header: 'Installeer DriveMe voor chauffeurs',
        body: `Installeer de DriveMe app voor
              snelle en gemakkelijke toegang
              tot jouw ritten.`
      },
      passengerTexts: {
        header: 'Installeer DriveMe',
        body: `Installeer de DriveMe app om
              snel en gemakkelijke
              ritten te boeken`
      },
      rentABobTexts: {
        header: 'Installeer Rent-A-Bob',
        body: `Installeer deze app om snel en gemakkelijk ritten te boeken`
      },
      isRentABobApp: AppConfig.brand === BRAND.RENTABOB
    }
  },
  computed: {
    appIcon () {
      if (AppConfig.brand === BRAND.RENTABOB) {
        return require('../../assets/icons/rab-app-icon.png')
      } else {
        return this.$store.getters.getEnv.envVersion.siteVersion === 'ROLE_PASSENGER' ? require('../../assets/icons/passenger-app-icon.svg') : require('../../assets/icons/driver-app-icon.svg')
      }
    },
    descTexts () {
      if (AppConfig.brand === BRAND.RENTABOB) {
        return this.rentABobTexts
      } else {
        return this.$store.getters.getEnv.envVersion.siteVersion === 'ROLE_PASSENGER' ? this.passengerTexts : this.driverTexts
      }
    }
  },
  watch: {
    dialog () {
      this.$store.dispatch('setAddToIosState', this.dialog)
    }
  },
  beforeMount () {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    if (iOS) {
      if (localStorage.getItem('iosNotifier') == null) {
        localStorage.setItem('iosNotifier', true)
        this.dialog = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .icons-row {
    width: 348px;
    margin-left: -42px;
  }
  .fake-icon {
    width: 61px;
    height: 61px;
    border-radius: 10px;
    background-color: rgba(112, 112, 112, 0.3);
  }
  .middle-icon {
    width: 61px;
    height: 61px;
  }
  .rab-app-icon {
    border-radius: 10px;
    border: 1px solid;
  }
  .share-icon {
    padding-top: 4px;
    margin-bottom: -4px;
  }
</style>
