<template>
  <v-dialog
    v-model="open"
    max-width="500">
    <v-card>
      <v-card-title class="headline">{{ heading }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="error"
          @click="open = false">{{ cancel || 'Cancel' }}</v-btn>
        <v-btn
          color="primary"
          @click="$emit('onConfirm')">{{ confirm || 'Yes' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['heading', 'message', 'onConfirm', 'cancel', 'confirm'],
  data () {
    return {
      open: false
    }
  },
  methods: {
    openDialog () {
      this.open = true
    },
    close () {
      this.open = false
    }
  }
}
</script>
