export const userTypes = {
  admin: {
    title: 'Admin'
  },

  driver: {
    title: 'Driver'
  },

  passenger: {
    title: 'Customer'
  }
}
