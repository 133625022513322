/**
 * Sidebar Module
 */

import { driverMenus } from './data.js'

const state = {
  driverMenus
}

const getters = {
  driverMenus: state => {
    return state.driverMenus
  }
}

const actions = {
  setActiveMenuGroup (context, payload) {
    context.commit('setActiveMenuGroupHandler', payload)
  }
}

const mutations = {
  setActiveMenuGroupHandler (state, router) {
    let fullPath = ''
    if (router.pathURL) {
      fullPath = router.pathURL
    } else {
      fullPath = router.history.current.fullPath
    }
    let path = fullPath.split('/')
    let matchedPath = '/' + path[2] + '/' + path[3]
    for (const category in state.driverMenus) {
      for (const menuGroup in state.driverMenus[category]) {
        if (state.driverMenus[category][menuGroup].items !== null) {
          for (const matched in state.driverMenus[category][menuGroup].items) {
            if (state.driverMenus[category][menuGroup].items[matched].path === matchedPath || state.driverMenus[category][menuGroup].items[matched].path === fullPath) {
              state.driverMenus[category][menuGroup].active = true
            }
          }
        }
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
