// Sidebar Routers
export const supportMenus = {
  'message.general': [
    {
      title: 'message.rideOverview',
      path: '/dashboard/ride-overview',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.users',
      path: '/tables/users',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.passengers',
      path: '/tables/passengers',
      exact: true,
      label: 'Old'
    },
    {
      title: 'Secretaries',
      path: '/tables/secretaries',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.approveChanges',
      path: '/approve-changes',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.webNotifications',
      path: '/tables/web-notifications',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.reviews',
      path: '/tables/reviews',
      exact: true,
      label: 'Old'
    }
  ]
}

export const financeMenus = {
  'message.general': [
    {
      title: 'message.rideOverview',
      path: '/dashboard/ride-overview',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.passengers',
      path: '/tables/passengers',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.invoices',
      path: '/tables/invoices',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.customInvoices',
      path: '/tables/custom-invoices',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.reverseInvoices',
      path: '/tables/reverse-invoices',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.combinedInvoices',
      path: '/tables/combined-invoices',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.exactOnline',
      path: '/exact-online',
      exact: true,
      label: 'Old'
    }
  ]
}

export const adminMenus = {
  'message.general': [
    {
      action: 'zmdi-view-dashboard',
      title: 'message.finance',
      label: 'Old',
      default: true,
      disabled: true
    },
    {
      title: 'message.billPassengers',
      path: '/tables/bill-passengers',
      exact: true,
      label: 'Old',
      level: 3
    },
    {
      title: 'message.passengerInvoices',
      path: '/tables/combined-invoices',
      exact: true,
      label: 'Old',
      level: 3
    },
    {
      title: 'message.payDrivers',
      path: '/tables/pay-drivers',
      exact: true,
      label: 'Old',
      level: 3
    },
    {
      title: 'message.driverInvoices',
      path: '/tables/reverse-combined-invoices',
      exact: true,
      label: 'Old',
      level: 3
    },
    {
      title: 'message.customInvoices',
      path: '/tables/custom-invoices',
      exact: true,
      label: 'Old',
      level: 3
    },
    {
      title: 'message.rideOverview',
      path: '/dashboard/ride-overview',
      exact: true,
      label: 'Old',
      default: true
    },
    {
      title: 'message.users',
      path: '/tables/users',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.passengers',
      path: '/tables/passengers',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.drivers',
      path: '/tables/drivers',
      exact: true,
      label: 'Old'
    },
    {
      title: 'Secretaries',
      path: '/tables/secretaries',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.approveChanges',
      path: '/approve-changes',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.emailSendingMessages',
      path: '/tables/messages',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.webNotifications',
      path: '/tables/web-notifications',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.driverTypes',
      path: '/tables/driver-types',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.driverCertificates',
      path: '/tables/driver-certificates',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.driverSchedule',
      path: '/tables/drivers-schedule',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.reviews',
      path: '/tables/reviews',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.posts',
      path: '/tables/posts',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.regions',
      path: '/tables/regions',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.brandsConfig',
      path: '/tables/brands-config',
      exact: true,
      label: 'Old'
    },
    {
      title: 'message.exactOnline',
      path: '/exact-online',
      exact: true,
      label: 'Old'
    }
  ]
}
