import VueCookies from 'vue-cookies'
import AppConfig from 'Constants/AppConfig'
import { store } from '../store/store'

var myDate = new Date()
myDate.setMonth(myDate.getMonth() + 12)

const storeToken = (data) => {
  let domain = store.getters.getEnv.env === 'dev' ? `${AppConfig.brand}.local` : `${AppConfig.brand}.nl`
  VueCookies.set('token', data.token, '365d', null, domain)
  VueCookies.set('refreshToken', data.refreshToken, '365d', null, domain)
  VueCookies.set('expireAt', data.expireAt.timestamp, '365d', null, domain)
}

const clearToken = () => {
  let domain = store.getters.getEnv.env === 'dev' ? `${AppConfig.brand}.local` : `${AppConfig.brand}.nl`

  localStorage.removeItem('isAuth')

  VueCookies.remove('token', null, domain)
  VueCookies.remove('refreshToken', null, domain)
  VueCookies.remove('expireAt', null, domain)
}

const getToken = () => VueCookies.get('token')

const isTokenDataValid = () => {
  const token = VueCookies.get('token')
  const refreshToken = VueCookies.get('refreshToken')
  const exp = VueCookies.get('expireAt')

  return token && refreshToken && exp
}

const isTokenExpired = () => {
  const leeway = 0
  const timestamp = Math.floor(Date.now() / 1000) + leeway

  return timestamp > Number(VueCookies.get('expireAt'))
}

export { storeToken, clearToken, isTokenDataValid, isTokenExpired, getToken }
