import Full from 'Container/Full'

// dashboard components
const RideOverview = () => import('../views/admin/ride_overview/index.vue')
const ExactOnline = () => import('../views/admin/exactOnline/index.vue')
const CrudPassenger = () => import('../views/admin/crud/CrudPassenger.vue')
const CrudEditPassenger = () => import('../views/admin/crud/CrudEditPages/CrudEditPassenger.vue')
const CrudInvoice = () => import('../views/admin/crud/CrudInvoice.vue')
const CrudEditInvoice = () =>
  import('../views/admin/crud/CrudEditPages/CrudEditInvoice.vue')
const CrudInvoiceCustom = () => import('../views/admin/crud/CrudInvoiceCustom.vue')
const CrudEditInvoiceCustom = () => import('../views/admin/crud/CrudEditPages/CrudEditInvoiceCustom.vue')
const CrudInvoiceReverse = () => import('../views/admin/crud/CrudInvoiceReverse.vue')
const CrudEditInvoiceReverse = () => import('../views/admin/crud/CrudEditPages/CrudEditInvoiceReverse.vue')
const CrudInvoiceCombined = () => import('../views/admin/crud/CrudInvoiceCombined.vue')
const CrudEditInvoiceCombined = () => import('../views/admin/crud/CrudEditPages/CrudEditInvoiceCombined.vue')
const CrudPassengerFinance = () => import('../views/admin/crud/CrudPassengerFinance.vue')
const CrudDriversFinance = () => import('../views/admin/crud/CrudDriverFinance.vue')

export default [
  {
    path: '/',
    component: Full,
    redirect: '/dashboard/ride-overview',
    children: [
      {
        path: '/dashboard/ride-overview',
        component: RideOverview,
        meta: {
          requiresAuth: true,
          title: 'Rides',
          breadcrumb: null
        }
      },
      {
        path: '/exact-online',
        component: ExactOnline,
        meta: {
          requiresAuth: true,
          title: 'Exact Online',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers',
        component: CrudPassenger,
        meta: {
          requiresAuth: true,
          title: 'Customers',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers/:id',
        component: CrudEditPassenger,
        meta: {
          requiresAuth: true,
          title: 'Customer',
          breadcrumb: null
        }
      },
      {
        path: '/tables/invoices',
        component: CrudInvoice,
        meta: {
          requiresAuth: true,
          title: 'Invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/invoices/:id',
        component: CrudEditInvoice,
        meta: {
          requiresAuth: true,
          title: 'Invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/custom-invoices',
        component: CrudInvoiceCustom,
        meta: {
          requiresAuth: true,
          title: 'Custom Invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/custom-invoices/:id',
        component: CrudEditInvoiceCustom,
        meta: {
          requiresAuth: true,
          title: 'Custom Invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reverse-invoices',
        component: CrudInvoiceReverse,
        meta: {
          requiresAuth: true,
          title: 'Reverse Invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reverse-invoices/:id',
        component: CrudEditInvoiceReverse,
        meta: {
          requiresAuth: true,
          title: 'Reverse Invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/combined-invoices',
        component: CrudInvoiceCombined,
        meta: {
          requiresAuth: true,
          title: 'Combined Invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/combined-invoices/:id',
        component: CrudEditInvoiceCombined,
        meta: {
          requiresAuth: true,
          title: 'Combined Invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers-finance',
        component: CrudPassengerFinance,
        meta: {
          requiresAuth: true,
          title: 'Customers Finance',
          breadcrumb: null
        }
      },
      {
        path: '/tables/drivers-finance',
        component: CrudDriversFinance,
        meta: {
          requiresAuth: true,
          title: 'Drivers Finance',
          breadcrumb: null
        }
      },
      {
        path: '*',
        name: 'arides',
        component: RideOverview,
        redirect: '/dashboard/ride-overview'
      }
    ]
  }
]
