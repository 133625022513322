<!-- Side Structure -->
<template>
  <div
    :class="sidebarSelectedFilter.class"
    class="sidebar">
    <vue-perfect-scrollbar
      :settings="settings"
      class="scroll-area">
      <v-toolbar
        flat
        class="transparent scroll-area navigation">
        <v-list>
          <app-logo/>
          <user-block/>
          <template v-for="(category, key) in menus">
            <div
              :key="key"
              class="mt-4">
              <template v-for="item in category">
                <template v-if="item.items != null">
                  <v-list-group
                    :key="item.title"
                    v-model="item.active"
                    :prepend-icon="item.icon"
                    append-icon=""
                    no-action
                  >
                    <v-list-tile slot="activator">
                      <v-list-tile-content>
                        <v-list-tile-title v-if="item.items!= null">
                          <i
                            :class="item.action"
                            class="mr-3 zmdi"/>
                          <span >{{ $t(item.title) }}</span>
                        </v-list-tile-title>

                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-for="subItem in item.items"
                      v-if="subItem !== null"
                      :key="subItem.title"
                      :to="!subItem.exact ? `/${getCurrentAppLayoutHandler() + subItem.path}` : subItem.path"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t(subItem.title) }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list-group>
                </template>
                <template v-else>
                  <v-list-group
                    :key="item.title"
                    v-model="item.active"
                    :class="`not-submenu ${item.level && 'ml-' + item.level} ${item.default && !item.active && 'black darken-4'}`"
                    :disabled="item.disabled"
                    append-icon=""
                    no-action
                  >
                    <v-list-tile slot="activator">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          <router-link :to="!item.exact ? `/${getCurrentAppLayoutHandler() + item.path}` : item.path">
                            <!-- <i
                              :class="item.action"
                              class="mr-3 zmdi"/>
                            <v-icon
                              size="18"
                              style="width: 20px;">{{ item.icon }}</v-icon> -->
                            <span class="white-14">{{ $t(item.title) }}</span>
                            <v-badge
                              :value="$store.getters.getUser && $store.getters.getUser.chat && Boolean($store.getters.getUser.chat.unread)"
                              class="unread-messages-badge"
                              color="red">
                              <template v-slot:badge>
                                <span v-if="$store.getters.getUser.chat && parseInt($store.getters.getUser.chat.unread) > 99">!</span>
                                <span v-else-if="$store.getters.getUser.chat">{{ $store.getters.getUser.chat.unread }}</span>
                              </template>
                            </v-badge>
                          </router-link>
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>

                  </v-list-group>
                </template>
              </template>
            </div>
          </template>

          <v-list-group
            class="not-submenu"
            append-icon=""
            no-action
          >

            <v-list-tile slot="activator">
              <v-list-tile-content>
                <v-list-tile-title
                  id="logout-btn"
                  @click="logOut">
                  <a class="router-link-exact-active router-link-active logout-scoped">
                    <i class="mr-3 zmdi"/>
                    <v-icon size="18">fal fa-sign-out</v-icon>
                    <span class="ml-3 white-16">Uitloggen</span>
                  </a>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

          </v-list-group>

          <div class="switch-user">
            <v-list-tile
              v-for="(userlink, index) in switchUserLinks"
              slot="activator"
              :key="index">
              <v-list-tile-content>
                <v-list-tile-title
                  @click="userlink.action()">
                  <a class="router-link-exact-active router-link-active logout-scoped">
                    <i class="mr-3 zmdi"/>
                    <v-icon size="18">fal fa-sign-out</v-icon>
                    <span class="ml-3 white-16">{{ userlink.title }}</span>
                  </a>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </div>

        </v-list>
      </v-toolbar>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import axios from 'axios'
import UserBlock from './UserBlock'
import { textTruncate, getCurrentAppLayout } from 'Helpers/helpers'
import { mapGetters } from 'vuex'
import AppLogo from 'Components/AppLogo/AppLogo'

export default {
  components: {
    UserBlock,
    AppLogo
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 160
      },
      switchUserLinks: []
    }
  },
  computed: {
    ...mapGetters(['sidebarSelectedFilter', 'userType', 'menus'])
  },
  beforeMount () {
    // highlight active tab
    this.menus['message.general'].forEach(item => {
      this.$set(item, 'active', item.path === window.location.pathname)
    })

    if (this.$store.getters.getUser.roles.includes('ROLE_ADMIN') && this.$store.getters.getEnv.envVersion.siteVersion !== 'ROLE_ADMIN') {
      this.switchUserLinks.push(
        {
          title: 'To admin',
          icon: 'ti-exchange-vertical mr-3 primary--text',
          action: () => this.changeEnvTo('admin')
        }
      )
    }
    if (this.$store.getters.getUser.roles.includes('ROLE_SUPPORT') && this.$store.getters.getEnv.envVersion.siteVersion !== 'ROLE_ADMIN') {
      this.switchUserLinks.push(
        {
          title: 'To driver',
          icon: 'ti-exchange-vertical mr-3 primary--text',
          action: () => this.changeEnvTo('admin')
        }
      )
    }
    if (this.$store.getters.getUser.roles.includes('ROLE_FINANCE') && this.$store.getters.getEnv.envVersion.siteVersion !== 'ROLE_ADMIN') {
      this.switchUserLinks.push(
        {
          title: 'To customer',
          icon: 'ti-exchange-vertical mr-3 primary--text',
          action: () => this.changeEnvTo('admin')
        }
      )
    }
  },
  methods: {
    async logOut () {
      const config = {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        withCredentials: true
      }

      const api = axios.create(config)
      localStorage.removeItem('isAuth')
      api.request({
        url: `${this.$store.getters.config.apiUrl}/token/kill`,
        method: 'post',
        data: {}
      })
      this.$store.dispatch('logoutUserFromFirebase', this.$router)
    },
    changeEnvTo (env) {
      window.location.href = 'https://' + env + this.$store.getters.getEnv.envVersion.domains[this.$store.getters.getEnv.env]
    },
    textTruncate (text) {
      return textTruncate(text, 18)
    },
    getCurrentAppLayoutHandler () {
      return getCurrentAppLayout(this.$router)
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  /deep/ .v-list__group__header {
    padding-left: 18px;
  }
}
.switch-user {
  bottom: 0;
  position: absolute;
}

.unread-messages-badge {
  position: absolute;
  top: 17px;

  .v-badge__badge {
    flex-wrap: nowrap !important;
  }
}
</style>

<style lang="scss">
  .v-navigation-drawer .navigation .v-list .v-list__group .v-list__group__items .v-list__tile--link {
    padding: 0 0 0 18px !important;
  }
</style>
