import Full from 'Container/Full'

// dashboard components
const RideOverview = () => import('../views/admin/ride_overview/index.vue')
const ExactOnline = () => import('../views/admin/exactOnline/index.vue')
const CrudUser = () => import('../views/admin/crud/CrudUser.vue')
const CrudDriver = () => import('../views/admin/crud/CrudDriver.vue')
const CrudPassenger = () => import('../views/admin/crud/CrudPassenger.vue')
const CrudSecretary = () => import('../views/admin/crud/CrudSecretary.vue')
const ChangePassword = () => import('../views/pages/ChangePassword.vue')
const ApproveChanges = () => import('../views/admin/tools/ApproveChanges.vue')
const CrudEditPassenger = () => import('../views/admin/crud/CrudEditPages/CrudEditPassenger.vue')
const CrudCreatePassenger = () => import('../views/admin/crud/CrudCreatePages/CrudCreatePassenger.vue')
const CrudEditDriver = () => import('../views/admin/crud/CrudEditPages/CrudEditDriver.vue')
const CrudWebNotification = () => import('../views/admin/crud/CrudWebNotification.vue')
const CrudDriverType = () => import('../views/admin/crud/CrudDriverType.vue')
const CrudInvoice = () => import('../views/admin/crud/CrudInvoice.vue')
const CrudEditInvoice = () =>
  import('../views/admin/crud/CrudEditPages/CrudEditInvoice.vue')
const CrudDriverCertificate = () => import('../views/admin/crud/CrudDriverCertificate.vue')
const CrudEditWebNotification = () => import('../views/admin/crud/CrudEditPages/CrudEditWebNotification.vue')
const CrudEditDriverType = () => import('../views/admin/crud/CrudEditPages/CrudEditDriverType.vue')
const CrudEditDriverCertificate = () => import('../views/admin/crud/CrudEditPages/CrudEditDriverCertificate.vue')
const CrudReview = () => import('../views/admin/crud/CrudReview.vue')
const CrudEditReview = () => import('../views/admin/crud/CrudEditPages/CrudEditReview.vue')
const CrudPost = () => import('../views/admin/crud/CrudPost.vue')
const CrudEditPost = () => import('../views/admin/crud/CrudEditPages/CrudEditPost.vue')
const CrudRegion = () => import('../views/admin/crud/CrudRegion.vue')
const CrudEditRegion = () => import('../views/admin/crud/CrudEditPages/CrudEditRegion.vue')
const CrudEditUser = () => import('../views/admin/crud/CrudEditPages/CrudEditUser.vue')
const CrudEditSecretary = () => import('../views/admin/crud/CrudEditPages/CrudEditSecretary.vue')
const CrudDriverScheduleOverview = () => import('../views/admin/crud/CrudDriverScheduleOverview.vue')
const CrudEditDriverScheduleOverview = () => import('../views/admin/crud/CrudEditPages/CrudEditDriverScheduleOverview.vue')
const CrudInvoiceCustom = () => import('../views/admin/crud/CrudInvoiceCustom.vue')
const CrudEditInvoiceCustom = () => import('../views/admin/crud/CrudEditPages/CrudEditInvoiceCustom.vue')
const CrudInvoiceReverse = () => import('../views/admin/crud/CrudInvoiceReverse.vue')
const CrudEditInvoiceReverse = () => import('../views/admin/crud/CrudEditPages/CrudEditInvoiceReverse.vue')
const CrudInvoiceCombined = () => import('../views/admin/crud/CrudInvoiceCombined.vue')
const CrudEditInvoiceCombined = () => import('../views/admin/crud/CrudEditPages/CrudEditInvoiceCombined.vue')
const CrudPassengerFinance = () => import('../views/admin/crud/CrudPassengerFinance.vue')
const CrudDriversFinance = () => import('../views/admin/crud/CrudDriverFinance.vue')
const CrudMessages = () => import('../views/admin/crud/CrudMessages.vue')
const CrudPayDrivers = () => import('../views/admin/crud/CrudPayDrivers.vue')
const CrudReverseCombinedInvoices = () => import('../views/admin/crud/CrudReverseCombinedInvoices.vue')
const CrudBillPassengers = () => import('../views/admin/crud/CrudBillPassengers.vue')
const CrudBrandsConfig = () => import('../views/admin/crud/CrudBrandsConfig.vue')

export default [
  {
    path: '/',
    component: Full,
    redirect: '/dashboard/ride-overview',
    children: [
      {
        path: '/dashboard/ride-overview',
        component: RideOverview,
        meta: {
          requiresAuth: true,
          title: 'Rides',
          breadcrumb: null
        }
      },
      {
        path: '/exact-online',
        component: ExactOnline,
        meta: {
          requiresAuth: true,
          title: 'Exact Online',
          breadcrumb: null
        }
      },
      {
        path: '/tables/users',
        component: CrudUser,
        meta: {
          requiresAuth: true,
          title: 'Users',
          breadcrumb: null
        }
      },
      {
        path: '/tables/users/:id',
        component: CrudEditUser,
        meta: {
          requiresAuth: true,
          title: 'User',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers',
        component: CrudPassenger,
        meta: {
          requiresAuth: true,
          title: 'Customers',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers/:id',
        component: CrudEditPassenger,
        meta: {
          requiresAuth: true,
          title: 'Customer',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passenger/create',
        component: CrudCreatePassenger,
        meta: {
          requiresAuth: true,
          title: 'Customer Create',
          breadcrumb: null
        }
      },
      {
        path: '/tables/drivers',
        component: CrudDriver,
        meta: {
          requiresAuth: true,
          title: 'Drivers',
          breadcrumb: null
        }
      },
      {
        path: '/tables/drivers/:id',
        component: CrudEditDriver,
        meta: {
          requiresAuth: true,
          title: 'Driver',
          breadcrumb: null
        }
      },
      {
        path: '/tables/secretaries',
        component: CrudSecretary,
        meta: {
          requiresAuth: true,
          title: 'Secretaries',
          breadcrumb: null
        }
      },

      {
        path: '/tables/secretaries/:id',
        component: CrudEditSecretary,
        meta: {
          requiresAuth: true,
          title: 'Secretary',
          breadcrumb: null
        }
      },
      {
        path: '/tables/messages',
        component: CrudMessages,
        meta: {
          requiresAuth: true,
          title: 'Messages',
          breadcrumb: null
        }
      },
      {
        path: '/tables/pay-drivers',
        component: CrudPayDrivers,
        meta: {
          requiresAuth: true,
          title: 'Pay Drivers',
          breadcrumb: null
        }
      },
      {
        path: '/tables/bill-passengers',
        component: CrudBillPassengers,
        meta: {
          requiresAuth: true,
          title: 'Bill Customers',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reverse-combined-invoices',
        component: CrudReverseCombinedInvoices,
        meta: {
          requiresAuth: true,
          title: 'Driver invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/drivers-schedule',
        component: CrudDriverScheduleOverview,
        meta: {
          requiresAuth: true,
          title: 'Drivers Schedules',
          breadcrumb: null
        }
      },
      {
        path: '/tables/drivers-schedule/:id',
        component: CrudEditDriverScheduleOverview,
        meta: {
          requiresAuth: true,
          title: 'Driver Schedule',
          breadcrumb: null
        }
      },
      {
        path: '/approve-changes',
        component: ApproveChanges,
        meta: {
          requiresAuth: true,
          title: 'Approve changes',
          breadcrumb: null
        }
      },
      {
        path: '/tables/web-notifications',
        component: CrudWebNotification,
        meta: {
          requiresAuth: true,
          title: 'Web Notifications',
          breadcrumb: null
        }
      },
      {
        path: '/tables/web-notifications/:id',
        component: CrudEditWebNotification,
        meta: {
          requiresAuth: true,
          title: 'Web Notification',
          breadcrumb: null
        }
      },
      {
        path: '/tables/invoices',
        component: CrudInvoice,
        meta: {
          requiresAuth: true,
          title: 'Invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/invoices/:id',
        component: CrudEditInvoice,
        meta: {
          requiresAuth: true,
          title: 'Invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/custom-invoices',
        component: CrudInvoiceCustom,
        meta: {
          requiresAuth: true,
          title: 'Custom invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/custom-invoices/:id',
        component: CrudEditInvoiceCustom,
        meta: {
          requiresAuth: true,
          title: 'Custom invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reverse-invoices',
        component: CrudInvoiceReverse,
        meta: {
          requiresAuth: true,
          title: 'Reverse invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reverse-invoices/:id',
        component: CrudEditInvoiceReverse,
        meta: {
          requiresAuth: true,
          title: 'Reverse invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/combined-invoices',
        component: CrudInvoiceCombined,
        meta: {
          requiresAuth: true,
          title: 'Combined Invoices',
          breadcrumb: null
        }
      },
      {
        path: '/tables/combined-invoices/:id',
        component: CrudEditInvoiceCombined,
        meta: {
          requiresAuth: true,
          title: 'Combined Invoice',
          breadcrumb: null
        }
      },
      {
        path: '/tables/driver-types',
        component: CrudDriverType,
        meta: {
          requiresAuth: true,
          title: 'Driver Types',
          breadcrumb: null
        }
      },
      {
        path: '/tables/driver-types/:id',
        component: CrudEditDriverType,
        meta: {
          requiresAuth: true,
          title: 'Driver Type',
          breadcrumb: null
        }
      },
      {
        path: '/tables/driver-certificates',
        component: CrudDriverCertificate,
        meta: {
          requiresAuth: true,
          title: 'Driver Certificates',
          breadcrumb: null
        }
      },
      {
        path: '/tables/driver-certificates/:id',
        component: CrudEditDriverCertificate,
        meta: {
          requiresAuth: true,
          title: 'Driver Certificate',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reviews',
        component: CrudReview,
        meta: {
          requiresAuth: true,
          title: 'Reviews',
          breadcrumb: null
        }
      },
      {
        path: '/tables/reviews/:id',
        component: CrudEditReview,
        meta: {
          requiresAuth: true,
          title: 'Review',
          breadcrumb: null
        }
      },
      {
        path: '/tables/posts',
        component: CrudPost,
        meta: {
          requiresAuth: true,
          title: 'Posts',
          breadcrumb: null
        }
      },
      {
        path: '/tables/posts/:id',
        component: CrudEditPost,
        meta: {
          requiresAuth: true,
          title: 'Post',
          breadcrumb: null
        }
      },
      {
        path: '/tables/regions',
        component: CrudRegion,
        meta: {
          requiresAuth: true,
          title: 'Regions',
          breadcrumb: null
        }
      },
      {
        path: '/tables/regions/:id',
        component: CrudEditRegion,
        meta: {
          requiresAuth: true,
          title: 'Region',
          breadcrumb: null
        }
      },
      {
        path: '/change-password',
        component: ChangePassword,
        meta: {
          requiresAuth: true,
          title: 'Change Password',
          header: 'Wachtwoord',
          breadcrumb: null
        }
      },
      {
        path: '/tables/passengers-finance',
        component: CrudPassengerFinance,
        meta: {
          requiresAuth: true,
          title: 'Customers Finance',
          breadcrumb: null
        }
      },
      {
        path: '/tables/drivers-finance',
        component: CrudDriversFinance,
        meta: {
          requiresAuth: true,
          title: 'Drivers Finance',
          breadcrumb: null
        }
      },
      {
        path: '/tables/brands-config',
        component: CrudBrandsConfig,
        meta: {
          requiresAuth: true,
          title: 'Brands Config',
          breadcrumb: null
        }
      },
      {
        path: '*',
        name: 'arides',
        component: RideOverview,
        redirect: '/dashboard/ride-overview'
      }
    ]
  }
]
