export default {
  primary: '#00D014',
  secondary: 'rgb(25, 22, 47)',

  accent: '#82B1FF',
  error: '#FF3739',
  info: '#00D0BD',
  success: '#5D92F4',
  warning: '#FFB70F'
}
