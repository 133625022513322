/**
 * App Entry File
 * Vuely: A Powerfull Material Design Admin Template
 * Copyright 2018. All Rights Reserved
 * Created By: The Iron Network, LLC
 * Made with Love
 */
import 'babel-polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify'
import * as Sentry from '@sentry/vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { Vue2Dragula } from 'vue2-dragula'
import VueQuillEditor from 'vue-quill-editor'
import wysiwyg from 'vue-wysiwyg'
import VueBreadcrumbs from 'vue2-breadcrumbs'
import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import AmCharts from 'amcharts3'
import AmSerial from 'amcharts3/amcharts/serial'
import AmAngularGauge from 'amcharts3/amcharts/gauge'
import Nprogress from 'nprogress'
import VueI18n from 'vue-i18n'
import VueTour from 'vue-tour'
import fullscreen from 'vue-fullscreen'
import InstantSearch from 'vue-instantsearch'
import VueVideoPlayer from 'vue-video-player'
import Croppa from 'vue-croppa'
import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

import './assets/fonts/css/all.css'

// global components
import GlobalComponents from './globalComponents'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
// app.vue
import App from './App'

// custom datetime picker
import DatetimePicker from 'vuetify-datetime-picker'

// router
import router from './router'

// themes
// import primaryTheme from './themes/primaryTheme'

// store
import { store } from './store/store'

import globals from './helpers/globals'

// include script file
import './lib/VuelyScript'

// include all css files
import './lib/VuelyCss'

// messages
import messages from './lang'

import AppConfig from 'Constants/AppConfig'

Vue.use(VueLodash, { lodash })

Vue.use(require('vue-cookies'))

Vue.mixin(globals)

Vue.use(Datetime)

// navigation guards before each
router.beforeEach((to, from, next) => {
  Nprogress.start()
  document.title = to.meta.title || 'DriveMe'
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem('user') === null && !localStorage.getItem('isAuth')) {
      next({
        path: '/session/login'
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

if ('serviceWorker' in window.navigator) {
  window.navigator.serviceWorker.register('./service-worker.js')
    .then(function (reg) {

    }).catch(function (err) {
      console.log(err)
    })
}

// navigation guard after each
router.afterEach((to, from) => {
  Nprogress.done()
  setTimeout(() => {
    const contentWrapper = document.querySelector('.v-content__wrap')
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0
    }
    const boxedLayout = document.querySelector('.app-boxed-layout .app-content')
    if (boxedLayout !== null) {
      boxedLayout.scrollTop = 0
    }
    const miniLayout = document.querySelector('.app-mini-layout .app-content')
    if (miniLayout !== null) {
      miniLayout.scrollTop = 0
    }
  }, 200)
})

// plugins
Vue.use(Vuetify, {
  theme: store.getters.selectedTheme.theme,
  iconfont: ['fa', 'mdi']
})
Vue.use(InstantSearch)
Vue.use(VueI18n)
Vue.use(AmCharts)
Vue.use(AmSerial)
Vue.use(VueTour)
Vue.use(AmAngularGauge)
Vue.use(Vue2Dragula)
Vue.use(VueQuillEditor)
Vue.use(VueResource)
Vue.use(wysiwyg, {})
Vue.use(VueBreadcrumbs)
Vue.use(Notifications, { velocity })
Vue.use(fullscreen)
Vue.use(GlobalComponents)
Vue.use(VueVideoPlayer)
Vue.use(Croppa)
Vue.use(DatetimePicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBtdO5k6CRntAMJCF-H5uZjTCoSGX95cdk' // Add your here your google map api key
  }
})

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: store.getters.selectedLocale.locale, // set locale
  messages // set locale messages
})

Sentry.setContext('brand', { brand: AppConfig.brand })

const SENTRY_ENVS = [AppConfig.ENV.STA, AppConfig.ENV.PROD]

if (SENTRY_ENVS.includes(AppConfig.env)) {
  Sentry.init({
    Vue,
    dsn: AppConfig.sentry.dsn,
    ignoreErrors: ['NavigationDuplicated', 'Illegal invocation'],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  })
}

/* eslint-disable no-new */
new Vue({
  store,
  i18n,
  router,
  components: { App },
  render: h => h(App)
}).$mount('#app')
