<!-- App Main Structure -->
<template>
  <div
    :class="`app-default-layout ${role === 'ROLE_PASSENGER' ? 'passenger-layout' : ''}`"
  >
    <template v-if="$store.getters.fullLoader">
      <rotate-square2/>
    </template>
    <template v-else>
      <!-- App Header -->
      <div v-if="brand === brandConst">
        <SidebarPassengerRentABob v-if="role === 'ROLE_PASSENGER' && ['ride', 'help'].includes(routeName)" />
      </div>
      <div v-else>
        <SidebarPassenger v-if="role === 'ROLE_PASSENGER' && ['ride', 'help'].includes(routeName)"/>
      </div>
      <app-header v-if="['ROLE_ADMIN', 'ROLE_DRIVER'].includes(role)" />
      <!-- App Main Content -->
      <v-content :class="['ride', 'help'].includes(routeName) ? 'custom-main' : ''">
        <!-- App Router -->
        <transition
          :enter-active-class="`animated ${['help', 'helpDetail'].includes(routeName) ? false : selectedRouterAnimation}`"
          name="router-anim">
          <router-view/>
        </transition>
      </v-content>

    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from 'Components/Header/Header.vue'
import Tour from 'Components/Tour/Tour'
import AppConfig from 'Constants/AppConfig'
import { getEnvVersion } from '../helpers/helpers'
import SidebarPassengerRentABob from '../views/rentabob-passenger/components/Sidebar'
import SidebarPassenger from '../views/passenger/components/Sidebar'
import { BRAND } from '../constants/common'

export default {
  components: {
    appHeader: Header,
    Tour,
    SidebarPassenger,
    SidebarPassengerRentABob
  },
  data () {
    return {
      loading: true,
      role: '',
      brand: '',
      brandConst: BRAND.RENTABOB,
      routeName: ''
    }
  },
  computed: {
    ...mapGetters(['selectedRouterAnimation'])
  },
  watch: {
    $route (to, from) {
      this.routeName = to.name
    }
  },
  beforeMount () {
    this.role = getEnvVersion().siteVersion
    this.brand = AppConfig.brand
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
      setTimeout(() => {
        if (AppConfig.enableUserTour) {
          this.$tours['vuelyTour'].start()
        }
      }, 1000)
    }, 2000)

    this.routeName = this.$route.name
  }
}
</script>

<style lang="scss" scoped>
.passenger-layout {
  background-color: #fff;

  .custom-main {
    height: calc(100vh - 46px);
  }
}
</style>
