import Vue from 'vue'
import Router from 'vue-router'

import AppConfig from 'Constants/AppConfig'
import { BRAND } from 'Constants/common'

import drivemeRoutes from './driveme_index'

const routes = {
  [BRAND.DRIVE_ME]: drivemeRoutes
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: routes[AppConfig.brand]
})
