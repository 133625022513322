/**
 * Helpers Functions
 */
import moment from 'moment'
import AppConfig from 'Constants/AppConfig'

// Get Date
export function getTheDate (timestamp, format) {
  let time = timestamp * 1000
  let formatDate = format || 'MM-DD-YYYY'
  return moment(time).format(formatDate)
}

// Convert Date To Timestamp
export function convertDateToTimeStamp (date, format) {
  let formatDate = format || 'YYYY-MM-DD'
  return moment(date, formatDate).unix()
}

/**
 * Text Truncate
 */
export function textTruncate (str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA (hex, alpha) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    )
  }
  throw new Error('Bad Hex')
}

/**
 * Function to return currenr app layout
 */
export function getCurrentAppLayout (router) {
  let location = router.history.current.fullPath
  let path = location.split('/')
  return path[1]
}

export function cleanDefaultFields (data) {
  if (data) {
    delete data.id
    delete data.deletedAt
    delete data.createdAt
    delete data.modifiedAt
  }

  return data
}

export function getEnvVersion () {
  const { brand } = AppConfig

  const domains = {
    dev: `.${brand}.local`,
    tst: `-tst.${brand}.nl`,
    sta: `-sta.${brand}.nl`,
    prod: `.${brand}.nl`
  }

  return {
    siteVersion: 'ROLE_ADMIN',
    domains
  }
}

export function parseAddress (address) {
  if (address) {
    const addressArray = address.trim().split(',')
    const houseNumber = addressArray.length > 2 ? addressArray[0].trim().split(' ') : null
    const postCode = addressArray.length > 2 ? addressArray[1].trim().split(' ')[0] : ''
    const additional = houseNumber ? houseNumber[houseNumber.length - 1] : ''

    return {
      houseNumber: houseNumber ? houseNumber[houseNumber.length - 1].match(/\d+/g).join('') : '',
      postCode,
      additional: /[a-zA-Z]+/g.test(additional) ? additional.match(/[a-zA-Z]+/g).join('') : '',
      address
    }
  } else {
    return {
      houseNumber: '',
      postCode: '',
      additional: '',
      address: ''
    }
  }
}

export function isMobile () {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }

  return false
}

export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function createBase64File (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function parseDate (date) {
  if (date) {
    return new Date(date)
  }

  return null
}

export function getClientHeight (element) {
  return document.getElementsByClassName(element)[0] ? document.getElementsByClassName(element)[0].clientHeight : 500
}
