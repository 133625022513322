<template>
  <v-app
    id="inspire"
    :dark="darkMode"
    :class="[{
      'box-layout': boxLayout,
      'collapse-sidebar': collapseSidebar,
      'rtl-layout': rtlLayout
    }]"
  >

    <div
      v-if="$store.getters.showOverlay"
      class="overlay-block" />

    <div
      v-if="isIE"
      class="pt-3 px-2 dark-blue-16">
      Helaas. Internet Explorer wordt niet ondersteund door deze site. Gebruik Chrome, Edge of Firefox.
    </div>
    <router-view
      v-else
      :authenticated="authenticated"
      :auth="false"/>

    <notifications
      group="webNotifications"
      position="top right"
      animation-type="velocity"
      classes="web-notification"
    >
      <template
        slot="body"
        slot-scope="props">
        <div
          class="web-notification"
          @click="props.close"
        >
          <div>
            <a class="notification-title">
              {{ props.item.title }}
            </a>
            <div
              class="notification-content"
              v-html="props.item.text"
            >
              {{ props.item.text }}
            </div>
          </div>
          <a class="close">
            <img
              src="./assets/icons/close-icon-white.svg"
              alt="Close"
            >
          </a>
        </div>
      </template>
    </notifications>

    <notifications
      group="topRight"
      position="top right"
      animation-type="velocity"
      classes="common-notifications"
      width="100%"
    />

    <add-to-homeios />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppConfig from 'Constants/AppConfig'
import Request from './helpers/request'
import { WEB_NOTIFICATION_TYPES } from './constants/common'

import AddToHomeios from './modules/layout/AddToHomeIOS.vue'
import { clearToken } from './helpers/tokenHelper'
import { getEnvVersion } from './helpers/helpers'
import { oneSignalInit } from './helpers/oneSignal'
import * as Sentry from '@sentry/vue'

export default {
  components: { AddToHomeios },
  mixins: [Request],
  data () {
    return {
      animation: {
        enter: {
          opacity: [1, 0],
          translateX: [0, -300],
          scale: [1, 0.2]
        },
        leave: {
          opacity: 0,
          height: 0
        }
      },
      authenticated: false,
      isIE: false
    }
  },
  computed: {
    ...mapGetters([
      'darkMode',
      'collapseSidebar',
      'boxLayout',
      'rtlLayout',
      'selectedLocale'
    ])
  },
  mounted () {
    if (
      this.selectedLocale.locale === 'he' ||
      this.selectedLocale.locale === 'ar'
    ) {
      this.$store.dispatch('rtlLayout')
    }
  },
  async beforeMount () {
    // Attach your callback function to the `window` object
    window.initMap = function () {
      // JS API is loaded and available
    }

    const { data } = await this.request('GET', '/config', {})
    if (data && data.GOOGLE_MAPS_API_KEY) {
      const s = document.createElement('script')

      s.type = 'text/javascript'
      s.src = 'https://maps.googleapis.com/maps/api/js?key=' + data.GOOGLE_MAPS_API_KEY + '&libraries=places&callback=initMap'

      document.getElementsByTagName('body')[0].before(s)
    }
    this.checkForIE()

    let getEnv = () => {
      if (location.hostname.endsWith('.local')) return 'dev'
      if (location.hostname.endsWith(`-tst.${AppConfig.brand}.nl`)) return 'tst'
      if (location.hostname.endsWith(`-sta.${AppConfig.brand}.nl`)) return 'sta'
      return 'prod'
    }

    this.$cookies.config('365d')

    this.addLink('/site.webmanifest')

    this.$store.dispatch('setEnv', { env: getEnv(), envVersion: getEnvVersion() })
    this.$store.dispatch('setColorGuide', getEnvVersion().siteVersion)

    if (window.location.href.indexOf('/signup') === -1 && window.location.href.indexOf('/one-time-login') === -1 && window.location.href.indexOf('/secretary/join') === -1) {
      try {
        this.request('GET', '/me', {}, async ({ data }) => {
          if (!data.secretaryOf.length) {
            localStorage.removeItem('dmSecretaryId')
          }

          const { data: appConfigData } = await this.request('GET', '/me/app-config')
          oneSignalInit(appConfigData.oneSignal, AppConfig)

          if (data.roles.includes('ROLE_SIGN_UP')) {
            await this.killToken()
            clearToken()
            return this.$router.push('/session/login')
          }

          this.authorizeUser(data)
          Sentry.configureScope(scope => { scope.setUser({ id: data.id, email: data.email }) })

          if (this.$route.path === '/session/login') this.$router.replace({ path: '/' })

          if (this.$route.path !== '/session/login') {
            this.getWebNotifications()
          }
        }, null, () => {}, true)
      } catch (e) {
        this.$store.dispatch('setFullLoader', false)
      }
    }

    if ('ontouchstart' in window && localStorage.getItem('chromeAddToHomeNotifier') == null) {
      localStorage.setItem('chromeAddToHomeNotifier', true)
      var deferredPrompt

      let addToHomeScreen = (deferredPrompt) => {
        deferredPrompt.prompt()
        deferredPrompt.userChoice
          .then(function (choiceResult) {
            if (choiceResult.outcome === 'accepted') {
              // console.log('User accepted the A2HS prompt')
            } else {
              // console.log('User dismissed the A2HS prompt')
            }

            deferredPrompt = null
          })
      }

      window.addEventListener('beforeinstallprompt', function (e) {
        // Stash the event so it can be triggered later.
        deferredPrompt = e
        addToHomeScreen(deferredPrompt)
      })
    }
  },
  methods: {
    initMap () {
      // JS API is loaded and available
    },
    synhronizeWebNotifications (wNotificationsFromDB) {
      const result = []

      for (let i = 0; i < wNotificationsFromDB.length; i++) {
        const notifFromSession = this.$cookies.get(`singleNotify${wNotificationsFromDB[i].id}`)

        if (notifFromSession && notifFromSession.type !== wNotificationsFromDB[i].type) {
          result.push(wNotificationsFromDB[i])
          this.$cookies.set(`singleNotify${wNotificationsFromDB[i].id}`, JSON.stringify(wNotificationsFromDB[i]))
        }

        if (!notifFromSession) {
          result.push(wNotificationsFromDB[i])
        }
      }

      return result
    },
    getWebNotifications () {
      const webNotificationDuration = {
        [WEB_NOTIFICATION_TYPES[0]]: -1,
        [WEB_NOTIFICATION_TYPES[1]]: -1,
        [WEB_NOTIFICATION_TYPES[2]]: 10000
      }

      this.request('GET', '/me/web-notifications', {}, ({ data }) => {
        if (data.length) {
          const notifications = this.synhronizeWebNotifications(data)

          for (let i = 0; i < notifications.length; i++) {
            const notification = data[i]

            this.$notify({
              group: 'webNotifications',
              type: 'success',
              title: notification.title,
              text: notification.text,
              duration: webNotificationDuration[notification.type]
            })

            const notifFromSession = this.$cookies.get(`singleNotify${notification.id}`)

            if (
              (notification.type === WEB_NOTIFICATION_TYPES[1] && !notifFromSession) || (notifFromSession && notifFromSession.type !== notification.type)
            ) {
              this.$cookies.set(`singleNotify${notification.id}`, JSON.stringify(notification))
            }
          }
        }
      })
    },
    checkForIE () {
      var ua = window.navigator.userAgent

      var msie = ua.indexOf('MSIE ')
      if (msie > 0) {
        this.isIE = true
      }

      var trident = ua.indexOf('Trident/')
      if (trident > 0) {
        this.isIE = true
      }
    },
    addLink (href) {
      var link = document.createElement('link')
      link.rel = 'manifest'
      link.href = href
      document.head.appendChild(link)
    },
    setTitle (title) {
      document.title = title
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay-block {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(112, 112, 112, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
}

.web-notification {
  display: flex;
  align-items: center;
  background: #da1884;
  min-height: 64px;
  padding: 10px 20px;
  cursor: pointer;

  .notification-title {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }

  .notification-content {
    color: #fff;
    font-size: 12px;
  }

  .close {
    margin-left: auto;

    @media(max-width:599px) {
      align-self: flex-start;
    }
  }

  &.success {
    background-color: #da1884 !important;
  }
}
</style>
