
import moment from 'moment-timezone'

export default {
  methods: {
    momentDateTime (val) {
      return moment(val).format('DD-MM-YYYY HH:mm')
    },
    momentDateTimeDash (val) {
      return moment(val).format('DD-MM-YYYY [|] HH:mm')
    },
    momentDate (val) {
      return moment(val).format('DD-MM-YYYY')
    },
    momentTime (val) {
      return moment(val).format('HH:mm')
    },
    momentDateWords (val) {
      return moment(val).format('D MMMM YYYY')
    },
    timeStamp (val) {
      let time = moment(val)
      return time.format('YYYY-MM-DDTHH:mm')
    },
    isValidDate (val) {
      let date = moment(val, moment.ISO_8601)
      return date.isValid()
    },
    generatePassword (chars = 8) {
      let length = chars
      let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let retVal = ''
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      return retVal
    },
    isWebFontsDisabled () {
      let i = null

      if (document.getElementById('back-icon-material')) {
        i = document.getElementById('back-icon-material')
      } else {
        i = document.createElement('i')

        i.innerHTML = 'arrow_back_ios'
        i.style.fontFamily = 'Material Icons'
        i.style.visibility = 'hidden'
        i.style.position = 'fixed'
        i.style.left = '9999999px'
        i.style.fontSize = '15px'
        i.setAttribute('id', 'back-icon-material')

        document.body.appendChild(i)
      }

      return i.offsetWidth > 15
    },
    deepCopy (val) {
      return JSON.parse(JSON.stringify(val))
    },
    findInObject (objectArray, value, key = false) {
      if (typeof objectArray === 'object' && key) {
        return objectArray.find(obj => { let objValue = (typeof obj[key] === 'string' ? obj[key].trim() : obj[key]); return objValue != null ? objValue === value : false })
      } else if (typeof objectArray === 'object') {
        return objectArray.find(obj => { return obj === value })
      } else {
        return {}
      }
    },
    setTitle (title) {
      document.title = title
    }
  },
  computed: {
    global () {
      return {
        getRole: this.$store.getters.userType,
        getUser: this.$store.getters.getUser,
        getHeader: this.$store.getters.getHeader
      }
    },
    gFunc () {
      return {
        momentDateTime: (val) => { return this.momentDateTime(val) },
        momentDateTimeDash: (val) => { return this.momentDateTimeDash(val) },
        momentDate: (val) => { return this.momentDate(val) },
        momentTime: (val) => { return this.momentTime(val) },
        momentDateWords: (val) => { return this.momentDateWords(val) },
        timeStamp: (val) => { return this.timeStamp(val) },
        isValidDate: (val) => { return this.isValidDate(val) },
        generatePassword: (val) => { return this.generatePassword(val) },
        deepCopy: (val) => { return this.deepCopy(val) },
        findInObject: (array, value, key) => { return this.findInObject(array, value, key) },
        setTitle: (title) => { return this.setTitle(title) },
        isWebFontsDisabled: () => { return this.isWebFontsDisabled() }
      }
    }
  },
  beforeMount () {
    moment.locale('nl')
  }
}
