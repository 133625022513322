<template>
  <div class="site-logo">
    <router-link to="/">
      <img
        v-if="sidebarSelectedFilter.class == 'sidebar-overlay-light'"
        :src="darkLogo"
        alt="site logo"
        width="140"
        height="50">
      <img
        v-else
        :src="appLogo"
        alt="site logo"
        width="140"
        height="50">
    </router-link>
  </div>
</template>

<script>
import AppConfig from 'Constants/AppConfig'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      appLogo: AppConfig.appLogo,
      darkLogo: AppConfig.darkLogo
    }
  },
  computed: {
    ...mapGetters(['sidebarSelectedFilter'])
  }
}
</script>
