export const emails = [
  {
    value: false,
    id: 1,
    starred: true,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Mitchell Miles',
    userEmail: 'Mitchell@example.com',
    time: 1,
    avatarSrc: '/static/avatars/user-1.jpg',
    subject: 'Pellentesque vel est a orci tempus viverra.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 2,
    starred: false,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Jhon Smith',
    userEmail: 'Jhon@example.com',
    time: 2,
    avatarSrc: '/static/avatars/user-2.jpg',
    subject: 'Sed ullamcorper dolor ac vulputate laoreet.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 3,
    starred: true,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Merry Brown',
    userEmail: 'Merry@example.com',
    time: 3,
    avatarSrc: '/static/avatars/user-3.jpg',
    subject: 'Mauris venenatis massa ac mi consequat aliquam.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: null
  },
  {
    value: false,
    id: 4,
    starred: false,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Cristel Uno',
    userEmail: 'Cristel@example.com',
    time: 7,
    avatarSrc: '/static/avatars/user-4.jpg',
    subject: 'Donec feugiat lectus sit amet bibendum suscipit.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 5,
    starred: true,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Jone Miles',
    userEmail: 'Jone@example.com',
    time: 4,
    avatarSrc: '/static/avatars/user-5.jpg',
    subject: 'Nullam et enim id nulla porttitor consequat.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 6,
    starred: false,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Michall Miles',
    userEmail: 'Michall@example.com',
    time: 21,
    avatarSrc: '/static/avatars/user-6.jpg',
    subject: 'Proin vitae lorem iaculis, fringilla tortor ut, varius dolor.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 7,
    starred: true,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Rose Doe',
    userEmail: 'Rose@example.com',
    time: 12,
    avatarSrc: '/static/avatars/user-7.jpg',
    subject: 'Suspendisse id mauris sed orci iaculis vestibulum.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 8,
    starred: false,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Krishna Miles',
    userEmail: 'Krishna@example.com',
    time: 5,
    avatarSrc: '/static/avatars/user-8.jpg',
    subject: 'Sed at purus lacinia, rhoncus lorem at, facilisis mauris.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 9,
    starred: false,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Mitchell Rachel',
    userEmail: 'Mitchell@example.com',
    time: 1,
    avatarSrc: '/static/avatars/user-9.jpg',
    subject: 'Aliquam at dui eu dui placerat porta.',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 10,
    starred: false,
    inbox: true,
    draft: false,
    sent: false,
    spam: false,
    trash: false,
    userName: 'Jennifer Berganja',
    userEmail: 'Jennifer@example.com',
    time: 2,
    avatarSrc: '/static/avatars/user-10.jpg',
    subject: 'Welcome to our services',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  },
  {
    value: false,
    id: 11,
    starred: false,
    inbox: false,
    draft: false,
    sent: false,
    spam: false,
    trash: true,
    userName: 'Jennifer Berganja',
    userEmail: 'Jennifer@example.com',
    time: 2,
    avatarSrc: '/static/avatars/user-11.jpg',
    subject: 'Welcome to our services',
    body: "Lorem Ipsum is simply dummy subject of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy subject ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    attachments: [
      {
        id: 1,
        src: '/static/img/post-3.png',
        name: 'attachment-1.jpg'
      },
      {
        id: 2,
        src: '/static/img/post-4.png',
        name: 'attachment-2.jpg'
      },
      {
        id: 3,
        src: '/static/img/post-1.png',
        name: 'attachment-3.jpg'
      }
    ]
  }
]

export const mailboxes = [
  {
    id: 1,
    name: 'Inbox',
    action: 'zmdi zmdi-inbox'
  },
  {
    id: 2,
    name: 'Draft',
    action: 'zmdi-email-open'
  },
  {
    id: 3,
    name: 'Starred',
    action: 'zmdi-star'
  },
  {
    id: 4,
    name: 'Sent',
    action: 'zmdi-mail-send'
  },
  {
    id: 5,
    name: 'Spam',
    action: 'zmdi-alert-circle'
  },
  {
    id: 6,
    name: 'Trash',
    action: 'zmdi-delete'
  }
]
