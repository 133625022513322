<template>
  <div :class="[colClasses, { 'flex': colClasses}]">
    <fullscreen ref="fullscreen">
      <div
        :class="[customClasses, { 'd-none': close }]"
        class="app-card">
        <app-card-heading
          :heading="heading"
          :closeable="closeable"
          :reloadable="reloadable"
          :full-screen="fullScreen"
          :with-tabs="withTabs"
          :tabs="tabs"
          @onChangeTabCallback="onChangeTab"
          @onCollapse="onCollapse"
          @onReload="onReload"
          @onClose="onClose"
        />
        <app-card-content
          :extra-class="[contentCustomClass]"
          :full-block="fullBlock">
          <slot/>
        </app-card-content>
        <app-card-footer v-if="footer">
          <slot name="footer"/>
        </app-card-footer>
        <app-section-loader :status="reload"/>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import AppCardHeading from './AppCardHeading'
import AppCardFooter from './AppCardFooter'
import AppCardContent from './AppCardContent'
import AppSectionLoader from '../AppSectionLoader/AppSectionLoader'

export default {
  components: {
    AppCardHeading,
    AppCardFooter,
    AppCardContent,
    AppSectionLoader
  },
  props: [
    'heading',
    'colClasses',
    'customClasses',
    'fullBlock',
    'contentCustomClass',
    'closeable',
    'reloadable',
    'fullScreen',
    'footer',
    'withTabs',
    'tabs',
    'onChangeTabCallback'
  ],
  data () {
    return {
      reload: false,
      close: false
    }
  },
  methods: {
    onCollapse () {
      this.$refs['fullscreen'].toggle()
    },
    onReload () {
      this.reload = true
      let self = this
      setTimeout(() => {
        self.reload = false
      }, 1500)
    },
    onClose () {
      this.close = true
    },
    onChangeTab (value) {
      this.$emit('onChangeTabCallback', value)
    }
  }
}
</script>
