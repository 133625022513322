<template>
    <div>
        <slot />
    </div>
</template>
 
 <script>
 
export default {
    methods: {
        onKeyDown(e) {
            if (e.altKey || e.metaKey) {
                e.preventDefault()
                e.stopPropagation()

                switch (e.key) {
                    case 'i':
                        this.$children[0].$emit('input', (parseFloat(e.target.value) * 1.21).toFixed(2))
                        break;
                    case 'e':
                        this.$children[0].$emit('input', (parseFloat(e.target.value) / 1.21).toFixed(2))
                        break;
                    case 'j':
                        this.$children[0].$emit('input', (parseFloat(e.target.value) / 1.06).toFixed(2))
                        break;
                    case 'l':
                        this.$children[0].$emit('input', (parseFloat(e.target.value) * 1.06).toFixed(2))
                        break;
                }
            }
        }
    },
    mounted() {
        if (this.$children && this.$children.length && this.$children[0] && this.$children[0].$el) {
            this.$children[0].$el.addEventListener('keydown', this.onKeyDown);
        }
    },
    destroyed() {
        if (this.$children && this.$children.length && this.$children[0] && this.$children[0].$el) {
            this.$children[0].$el.removeEventListener('keydown', this.onKeyDown);
        }
    }
}
</script>
 