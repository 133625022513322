<template>
  <div
    class="close-button"
    @click.stop="$emit('close')">
    <img
      :src="imageIcon"
      alt="Close">
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'grey'
    }
  },
  computed: {
    imageIcon () {
      let icon = ''
      if (this.color === 'grey') icon = require('../../assets/icons/close-icon-grey.svg')
      if (this.color === 'blue') icon = require('../../assets/icons/close-icon-blue.svg')

      return icon
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
