const state = {
  data: []
}

const getters = {}

const actions = {
  setFaq (context, payload) {
    context.commit('setFaq', payload)
  }
}

const mutations = {
  setFaq (state, payload) {
    state.data = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
