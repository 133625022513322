import AppConfig from '../../../constants/AppConfig'

let routes = [
  { title: 'message.rides', name: 'rides', path: '/rides', exact: true, icon: 'fal fa-car', active: true },
  { title: 'message.help', path: '/help', exact: true, icon: 'fal fa-question-circle', active: false },
  // { title: 'message.messages', path: '/messages', exact: true, icon: 'fal fa-comments', active: false },
  { title: 'message.settings', path: '/account-settings', exact: true, icon: 'fal fa-cog', active: false }
]

if (AppConfig.secretaryEnabled) {
  routes = [
    ...routes,
    { title: 'message.secretaries', path: '/assistents', exact: true, icon: 'fal fa-user', active: false }
  ]
}

// Sidebar Routers
export const passengerMenus = {
  'message.general': routes
}
