<template>
  <v-list-tile
    class="sidebar-profile">
    <v-list-tile-avatar
      v-if="false">
      <!-- <img
        src="/static/avatars/user-13.jpg"
        alt="avatar"
        height="40"
        width="40"
        class="img-responsive" > -->
    </v-list-tile-avatar>
    <v-list-tile-content class="ml-1">
      <v-list-tile-title class="sidebar-user-info"><span
        v-if="$store.getters.getUser != null"
        class="white-14">{{ $store.getters.getUser.firstName }} {{ $store.getters.getUser.lastName }}</span></v-list-tile-title>
    </v-list-tile-content>
    <v-menu
      bottom
      offset-y
      left
      content-class="userblock-dropdown"
      nudge-top="-10"
      nudge-right="0"
      transition="slide-y-transition"
    >
      <v-btn
        slot="activator"
        dark
        icon
        class="ma-0">
        <v-icon v-if="webFontsDisabled === false">
          more_vert
        </v-icon>

        <span
          v-if="webFontsDisabled"
          class="dots-icon"
        >
          ...
        </span>
      </v-btn>
      <div class="dropdown-content">
        <div class="dropdown-top white--text primary">
          <span
            v-if="$store.getters.getUser != null"
            class="white--text fs-14 fw-bold d-block">{{ $store.getters.getUser.firstName }} {{ $store.getters.getUser.lastName }}</span>
          <span class="d-block fs-12 fw-normal"/>

        </div>
        <v-list class="dropdown-list">
          <template
            v-for="userLink in userLinks">
            <v-list-tile
              :key="userLink.id"
              @click="userLink.action">
              <i :class="userLink.icon"/>
              <span>{{ $t(userLink.title) }}</span>
            </v-list-tile>
          </template>
        </v-list>
      </div>
    </v-menu>
  </v-list-tile>
</template>

<script>
import { getCurrentAppLayout } from 'Helpers/helpers'

export default {
  data () {
    return {
      userLinks: [
        {
          id: 1,
          title: 'message.changePassword',
          icon: 'ti-more-alt mr-2 primary--text',
          action: () => this.changePassword()
        }
      ],
      webFontsDisabled: false
    }
  },
  mounted () {
    if (this.$store.getters.getEnv.envVersion.siteVersion === 'ROLE_DRIVER') {
      this.userLinks.push({
        id: 2,
        title: 'message.settings',
        icon: 'ti-settings mr-3 primary--text',
        action: () => this.$router.push({ path: '/account-settings' })
      })
    }

    if (this.$store.getters.getEnv.envVersion.siteVersion === 'ROLE_PASSENGER') {
      this.userLinks.push({
        id: 3,
        title: 'message.settings',
        icon: 'ti-settings mr-3 primary--text',
        action: () => this.$router.push({ path: '/account-settings' })
      })
    }

    this.webFontsDisabled = this.isWebFontsDisabled()
  },
  methods: {
    logoutUser () {
      this.$store.dispatch('logoutUserFromFirebase', this.$router)
    },
    changePassword () {
      this.$router.push({ path: '/change-password' })
    },
    getMenuLink (path) {
      return '/' + getCurrentAppLayout(this.$router) + path
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-user-info {
    max-width: 169px;
}

.dots-icon {
  transform: rotate(90deg);
  font-size: 24px;
  margin-left: 10px;
}
</style>
