const SignUpOne = () => import('Views/session/SignUpOne')
const LoginOne = () => import('Views/session/LoginOne')
const OneTimeLogin = () => import('Views/session/OneTimeLogin')
const SecretaryJoin = () => import('Views/session/SecretaryJoin')
const ForgotPassword = () => import('Views/session/ForgotPassword')
const ResetPassword = () => import('Views/session/ResetPassword')
const SignUpVerify = () => import('Views/session/SignUpVerify')
const Onboarding = () => import('Views/session/Onboarding')
const NoRole = () => import('../views/session/NoRole.vue')

export default [
  {
    path: '/signup',
    component: SignUpOne,
    meta: {
      title: 'Sign Up',
      breadcrumb: null
    }
  },
  {
    path: '/one-time-login',
    component: OneTimeLogin,
    meta: {
      title: 'Sign In',
      breadcrumb: null
    }
  },
  {
    path: '/secretary/join',
    component: SecretaryJoin,
    meta: {
      title: 'Login',
      breadcrumb: null
    }
  },
  {
    path: '/session/login',
    component: LoginOne,
    meta: {
      title: 'Login',
      breadcrumb: null
    }
  },
  {
    path: '/session/forgot-password',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      breadcrumb: null
    }
  },
  {
    path: '/session/reset-password',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
      breadcrumb: null
    }
  },
  {
    path: '/session/sign-up-verify',
    component: SignUpVerify,
    meta: {
      title: 'Set Password',
      breadcrumb: null
    }
  },
  {
    path: '/start',
    component: Onboarding,
    meta: {
      title: 'Set Password',
      breadcrumb: null
    }
  },
  {
    path: '/no-role',
    component: NoRole,
    meta: {
      title: 'No Role',
      breadcrumb: null
    }
  }
]
