<template>
  <v-dialog
    v-model="open"
    max-width="500">
    <v-card>
      <v-card-title class="headline">{{ heading }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>

      <div
        v-if="brand === brandName"
        class="px-3">
        <v-textarea
          v-model="comment"
          outline
          rows="6"
          class="custom-textarea"
          placeholder="Laat hier je bericht achter"/>
      </div>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          :id="cancelId"
          color="primary"
          @click="open = false">{{ cancel || 'Cancel' }}</v-btn>
        <v-btn
          :id="confirmId"
          color="error"
          @click="$emit('onConfirm', comment)">{{ confirm || 'Yes' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppConfig from '../../constants/AppConfig'
import { BRAND } from '../../constants/common'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['heading', 'message', 'onConfirm', 'cancel', 'confirm', 'cancelId', 'confirmId'],
  data () {
    return {
      open: false,
      brand: '',
      brandName: BRAND.DRIVE_ME,
      comment: ''
    }
  },
  beforeMount () {
    this.brand = AppConfig.brand
  },
  methods: {
    openDialog () {
      this.open = true
    },
    close () {
      this.open = false
    }
  }
}
</script>
