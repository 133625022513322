export default {
  primary: '#5D92F4',
  secondary: '#424242',
  success: '#00D014',
  warning: '#FFB70F',
  accent: '#82B1FF',
  error: '#FF3739',
  info: '#00D0BD',
  black: '#17182F',
  green: '#58BC48',
  pink: '#D91884',
  ashyGray: '#F1F1F1',
  lightBlue: '#109FC3',
  lightGray: '#EFEFEF',
  darkBlue: '#004253',
  darkOlive: '#263238',
  secondaryGreen: '#83BB26'
}
