/**
 * App Config File
 */
import { BRAND } from './common'

const ENV = {
  LOCAL: 'local',
  TST: 'tst',
  STA: 'sta',
  PROD: 'prod'
}

const brandName = {
  [BRAND.DRIVE_ME]: 'Drive Me',
  [BRAND.RENTABOB]: 'Rent a BOB'
}

const SITE_VERSION = {
  DRIVER: 'driver',
  PASSENGER: 'passenger',
  ADMIN: 'admin'
}

const getSiteVersion = () => {
  return SITE_VERSION.ADMIN
}

const siteVersion = getSiteVersion()

const BACKEND_LOCAL_URL = 'http://backend.driveme.local/api'

const BASE_URL = {
  [BRAND.DRIVE_ME]: {
    [ENV.LOCAL]: `https://backend-${siteVersion}-tst.driveme.nl/api`,
    [ENV.TST]: `https://backend-${siteVersion}-tst.driveme.nl/api`,
    [ENV.STA]: `https://backend-${siteVersion}-sta.driveme.nl/api`,
    [ENV.PROD]: `https://backend-${siteVersion}.driveme.nl/api`
  },
  [BRAND.RENTABOB]: {
    [ENV.LOCAL]: `https://backend-${siteVersion}-tst.rentabob.nl/api`,
    [ENV.TST]: `https://backend-${siteVersion}-tst.rentabob.nl/api`,
    [ENV.STA]: `https://backend-${siteVersion}-sta.rentabob.nl/api`,
    [ENV.PROD]: `https://backend-${siteVersion}.rentabob.nl/api`
  },
  [BRAND.EDRIVERS]: {
    [ENV.LOCAL]: `https://backend-${siteVersion}-tst.edrivers.nl/api`,
    [ENV.TST]: `https://backend-${siteVersion}-tst.edrivers.nl/api`,
    [ENV.STA]: `https://backend-${siteVersion}-sta.edrivers.nl/api`,
    [ENV.PROD]: `https://backend-${siteVersion}.edrivers.nl/api`
  }
}

const GOOGLE_API_KEYS = {
  [BRAND.DRIVE_ME]: {
    [ENV.LOCAL]: 'AIzaSyB5_9T_VJHvWAdDwdwZ5ujQssNtr3fAAxM',
    [ENV.TST]: 'AIzaSyB5_9T_VJHvWAdDwdwZ5ujQssNtr3fAAxM',
    [ENV.STA]: 'AIzaSyB5_9T_VJHvWAdDwdwZ5ujQssNtr3fAAxM',
    [ENV.PROD]: 'AIzaSyB5_9T_VJHvWAdDwdwZ5ujQssNtr3fAAxM'
    // TODO replace when API will be enabled
    // PROD: 'AIzaSyDL5RMLdeWeP8MbW3LUYfxdTbc9zhShhBQ'
  },
  [BRAND.RENTABOB]: {
    [ENV.LOCAL]: 'AIzaSyApfg3ybZ6gM1179hVsdG0u7ZpfPdSALMY',
    [ENV.TST]: 'AIzaSyApfg3ybZ6gM1179hVsdG0u7ZpfPdSALMY',
    [ENV.STA]: 'AIzaSyCxwDsRUxIG5cJy8Ol2RZdvtXG_osjpPPk',
    [ENV.PROD]: 'AIzaSyD4A3o_Ugg2sSWGpgHsFbjJu7s5n52J0Tg'
  }
}

const sentryDsn = {
  [SITE_VERSION.PASSENGER]: {
    [ENV.LOCAL]: 'https://c9d2b90cf1a54358a3a4a1567b253dc0@sentry.tmm-technology.com/18',
    [ENV.TST]: 'https://c9d2b90cf1a54358a3a4a1567b253dc0@sentry.tmm-technology.com/18',
    [ENV.STA]: 'https://c9d2b90cf1a54358a3a4a1567b253dc0@sentry.tmm-technology.com/18',
    [ENV.PROD]: 'https://5d070e76e77040699725cb1be1a6b9c2@sentry.tmm-technology.com/19'
  },
  [SITE_VERSION.DRIVER]: {
    [ENV.LOCAL]: 'https://4a8a3bd08be14eaa83695fe80c0c3d89@sentry.tmm-technology.com/20',
    [ENV.TST]: 'https://4a8a3bd08be14eaa83695fe80c0c3d89@sentry.tmm-technology.com/20',
    [ENV.STA]: 'https://4a8a3bd08be14eaa83695fe80c0c3d89@sentry.tmm-technology.com/20',
    [ENV.PROD]: 'https://bd25584ea1a547a4bb3cbe767d70df32@sentry.tmm-technology.com/21'
  },
  [SITE_VERSION.ADMIN]: {
    [ENV.LOCAL]: 'https://ae8cdf0b682b49d28670e94095853e86@sentry.tmm-technology.com/22',
    [ENV.TST]: 'https://ae8cdf0b682b49d28670e94095853e86@sentry.tmm-technology.com/22',
    [ENV.STA]: 'https://ae8cdf0b682b49d28670e94095853e86@sentry.tmm-technology.com/22',
    [ENV.PROD]: 'https://01aae127f99143cb88d930243ef9c54a@sentry.tmm-technology.com/23'
  }
}

const splittedOrigin = window.location.origin.split('.')
const brand = splittedOrigin[splittedOrigin.length - 2]

const getEnv = () => {
  if (window.location.origin.indexOf(`-${ENV.TST}`) !== -1) {
    return ENV.TST
  }

  if (window.location.origin.indexOf(`-${ENV.STA}`) !== -1) {
    return ENV.STA
  }

  if (window.location.origin.indexOf(`.${ENV.LOCAL}`) !== -1) {
    return ENV.LOCAL
  }

  return ENV.PROD
}

const env = getEnv()

const sentryConfig = {
  brand,
  dsn: sentryDsn[siteVersion][env]
}

export default {
  ENV,
  secretaryEnabled: true,
  appLogo: '/static/images/logos/logo.svg', // App Logo,
  darkLogo: '/static/img/site-dark-logo.png', // dark logo
  appLogo2: '/static/img/session.png', // App Logo 2 For Login & Signup Page
  brandName: brandName[brand], // Brand Name
  brand,
  env,
  BASE_URL,
  apiUrl: BASE_URL[brand][env],
  googleApiKey: GOOGLE_API_KEYS[brand][env],
  sentry: sentryConfig,
  copyrightText: 'DriveMe © 2021 All Rights Reserved.', // Copyright Text
  enableUserTour: false, // Enable User Tour
  weatherApiId: 'b1b15e88fa797225412429c1c50c122a1', // weather API Id
  weatherApiKey: '69b72ed255ce5efad910bd946685883a' // weather APi key,
}
