<template>
  <div>
    <v-select
      :items="passengersList"
      v-model="currentPassenger"
      color="secondary"
      label="Passenger"
      hide-details
      item-value="id"
      item-text="fullName"
      @change="onPassengerChange"
    />
  </div>
</template>

<script>
export default {
  name: 'PassengersSelect',
  data () {
    return {
      currentPassenger: null
    }
  },
  computed: {
    passengersList () {
      return [
        {
          email: this.$store.getters.getUser.email,
          firstName: this.$store.getters.getUser.firstName,
          lastName: this.$store.getters.getUser.lastName,
          id: this.$store.getters.getUser.id,
          fullName: `${this.$store.getters.getUser.firstName || ''} ${this.$store.getters.getUser.lastName || ''}`
        },
        ...this.$store.getters.getUser.secretaryOf.map(client => ({ ...client, fullName: `${client.firstName || ''} ${client.lastName || ''}` }))
      ]
    }
  },
  watch: {

  },
  mounted () {
    const dmSecretaryId = localStorage.getItem('dmSecretaryId')

    this.currentPassenger = JSON.parse(dmSecretaryId) || this.$store.getters.getUser.id
  },
  methods: {
    onPassengerChange (id) {
      localStorage.setItem('dmSecretaryId', id)
      window.location.href = '/rides'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
