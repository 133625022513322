// messages.js
export default {
  rideOverview: 'Ride overview',
  exactOnline: 'Exact online',
  rides: 'Ritoverzicht',
  help: 'Help',
  messages: 'Berichten',
  createRide: 'Create ride',
  approveChanges: 'Approve changes',
  webNotifications: 'Web notifications',
  emailSendingMessages: 'Email Sending Messages',
  driverTypes: 'Driver types',
  driverCertificates: 'Driver certificates',
  driverSchedule: 'Driver schedule',
  finance: 'Finance',
  reviews: 'Reviews',
  posts: 'Posts',
  regions: 'Regions',
  brandsConfig: 'Brands Config',

  admin: 'Admin',
  general: 'Algemeen',
  overview: 'Overview',
  customizer: 'Customizer',
  applications: 'Applications',
  features: 'Features',
  components: 'Components',
  salesAndVisitStats: 'Sales & Visits Stats',
  orderAndProjectStats: 'Order & Projects Stats',
  fitnessStats: 'Fitness Stats',
  supportAndUsefulWidgets: 'Support & Useful Widgets',
  dashboard: 'Dashboard',
  dashboardOverview: 'Dashboard Overview',
  dashboardv1: 'Dashboard V1',
  dashboardv2: 'Dashboard V2',
  widgets: 'Widgets',
  horizontalMenu: 'Horizontal Menu',
  pages: 'Pages',
  gallery: 'Gallery',
  pricing: 'Pricing',
  pricing1: 'Pricing 1',
  pricing2: 'Pricing 2',
  blank: 'Blank',
  session: 'Session',
  signUp1: 'Sign Up 1',
  signUp2: 'Sign Up 2',
  login1: 'Login 1',
  login2: 'Login 2',
  lockScreen: 'Lock Screen',
  uiElements: 'UI Elements',
  buttons: 'Buttons',
  cards: 'Cards',
  checkbox: 'Checkbox',
  carousel: 'Carousel',
  chips: 'Chips',
  datepicker: 'Datepicker',
  dialog: 'Dialog',
  grid: 'Grid',
  input: 'Input',
  list: 'List',
  menu: 'Menu',
  progress: 'Progress',
  radio: 'Radio',
  select: 'Select',
  slider: 'Slider',
  snackbar: 'Snackbar',
  tabs: 'Tabs',
  toolbar: 'Toolbar',
  tooltip: 'Tooltip',
  timepicker: 'Timepicker',
  forms: 'Forms',
  formValidation: 'Form Validation',
  stepper: 'Stepper',
  charts: 'Charts',
  vueChartjs: 'Vue Chartjs',
  vueEcharts: 'Vue Echarts',
  icons: 'Icons',
  themify: 'Themify',
  material: 'Material',
  tables: 'Tables',
  standard: 'Standard',
  slots: 'Slots',
  selectable: 'Selectable',
  searchRow: 'Search Row',
  maps: 'Maps',
  googleMaps: 'Google Maps',
  leafletMaps: 'Leaflet Maps',
  jvectorMap: 'Jvector Map',
  inbox: 'Inbox',
  users: 'Users',
  passengers: 'Customers',
  drivers: 'Drivers',
  userProfile: 'User Profile',
  usersList: 'Users List',
  calendar: 'Calendar',
  editor: 'Editor',
  quillEditor: 'Quill Editor',
  wYSIWYG: 'WYSIWYG',
  vue2Dragula: 'Vue2 Dragula',
  dragAndDrop: 'Drag And Drop',
  vueDraggable: 'Vue Draggable',
  draggableResizeable: 'Draggable Resizeable',
  chat: 'Chatten',
  todo: 'Todo',
  modules: 'Modules',
  user: 'User',
  miscellaneous: 'Miscellaneous',
  promo: 'Promo',
  connections: 'Connections',
  follow: 'Follow',
  unfollow: 'Unfollow',
  basicValidation: 'Basic Validation',
  validationWithSubmitAndclear: 'Validation with submit & clear',
  submit: 'Submit',
  clear: 'Clear',
  step1: 'Step 1',
  step2: 'Step 2',
  step3: 'Step 3',
  continue: 'Continue',
  cancel: 'Cancel',
  totalEarnings: 'Total Earnings',
  onlineRevenue: 'Online Revenue',
  offlineRevenue: 'Offline Revenue',
  marketingExpenses: 'Marketing Expenses',
  newCustomers: 'Money Spent',
  visitAndSalesStatistics: 'Visit & Sales Statistics',
  collapse: 'Collapse',
  reload: 'Reload',
  close: 'Close',
  bandwidthUsage: 'Bandwidth Usage',
  shoppingCart: 'Shopping Cart',
  totalDownloads: 'Total Downloads',
  productSales: 'Product Sales',
  projectStatus: 'Project Status',
  averageSteps: 'Average Steps',
  todaysDistance: "Today's Distance",
  todaysStep: "Today's Step",
  todaysGoal: "Today's Goal",
  calories: 'Calories',
  weeklySummary: 'Weekly Summary',
  supportTickets: 'Support Tickets',
  todoList: 'To Do List',
  newsletterCampaign: 'Newsletter Campaign',
  currentVisitors: 'Current Visitors',
  newMembers: 'New Members',
  addNewBlog: 'Add New Blog',
  add: 'Add',
  logOut: 'Log Out',
  totalAppMemory: 'Total App Memory',
  totalMemoryUsed: 'Total Memory Used',
  unreadMail: '12 Unread Mail',
  feedback: 'Feedback',
  flatButton: 'Flat Button',
  raisedButton: 'Raised Button',
  gradientButton: 'Gradient Button',
  buttonDropdownVariants: 'Button Dropdown Variants',
  buttonToggle: 'Button Toggle',
  icon: 'Icon',
  normal: 'Normal',
  noRole: 'Uw account is nog niet compleet aangemaakt',
  disabled: 'Disabled',
  floating: 'Floating',
  loaders: 'Loaders',
  sizing: 'Sizing',
  outline: 'Outline',
  round: 'Round',
  block: 'Block',
  search: 'Search',
  checkboxesBoolean: 'Checkboxes Boolean',
  checkboxesArray: 'Checkboxes Array',
  checkboxesStates: 'Checkboxes - States',
  checkboxesColors: 'Checkboxes - Colors',
  switches: 'Switches',
  default: 'Default',
  customTransition: 'Custom Transition',
  customDelimiter: 'Custom Delimiter',
  hideControls: 'Hide Controls',
  hideDelimiters: 'Hide Delimiters',
  colored: 'Colored',
  label: 'Label',
  closeable: 'Closeable',
  datePickerHorizontal: 'Date Picker Horizontal',
  datePickerVertical: 'Date Picker Vertical',
  datePickersInDialogAndMenu: 'Date Pickers- In Dialog And Menu',
  datePickersWithModal: 'Date Pickers With Modal',
  datePickersFormattingDate: 'Date Pickers - Formatting Date',
  simpleDialogs: 'Simple Dialogs',
  withoutActivator: 'Without Activator',
  scrollable: 'Scrollable',
  listOfAllMaterialIcons: 'List Of All Material Icons',
  arrowsAndDirectionsIcons: 'ARROWS & DIRECTION ICONS',
  webAppIcons: 'WEB APP ICONS',
  CONTROLICONS: 'CONTROL ICONS',
  TEXTEDITOR: 'TEXT EDITOR',
  LAYOUTICONS: 'LAYOUT ICONS',
  BRANDICONS: 'BRAND ICONS',
  newClients: 'New Clients',
  recurringClients: 'Recurring Clients',
  bounceRate: 'Bounce Rate',
  pageViews: 'Page Views',
  usefulWidgets: 'Useful Widgets',
  sale: 'Sale',
  sales: 'Sales',
  invoices: 'Invoices',
  customInvoices: 'Custom invoices',
  reverseInvoices: 'Reverse invoices',
  combinedInvoices: 'Combined invoices',
  passengerInvoices: 'Customer invoices',
  driverInvoices: 'Driver invoices',
  generateDriversInvoices: 'Generate drivers invoices',
  payDrivers: 'Pay drivers',
  billPassengers: 'Bill customers',
  reverseCombinedInvoices: 'Reverse combined invoices',
  generatePassengersInvoices: 'Generate customers invoices',
  referrals: 'Referrals',
  serverLoad: 'Server Load',
  recentSale: 'Recent Sale',
  supportRequest: 'Support Request',
  productSale: 'Product Sale',
  viewAll: 'VIEW ALL',
  barChart: 'Bar Chart',
  polarAreaChart: 'Polar Area Chart',
  lineChart: 'Line Chart',
  radarChart: 'Radar Chart',
  doughnutChart: 'Doughnut Chart',
  bubbleChart: 'Bubble Chart',
  gradientLineChart: 'Gradient Line Chart',
  pieChart: 'Pie Chart',
  funnelChart: 'Funnel Chart',
  loginNow: 'Login Now',
  createAccount: 'Create Account',
  termsOfService: 'Terms of Service',
  bySigningUpYouAgreeTo: 'By signing up you agree to',
  loginToAdmin: 'Login To Control Panel',
  enterUsernameAndPasswordToAccessControlPanelOf:
    'Enter username and password to access control panel of',
  havingAnAccount: 'Having an account?',
  quickLinks: 'Quick Links',
  plans: 'Plans',
  chooseThePlanThatWorksForYou: 'Choose the plan that works for you.',
  monthly: 'Monthly',
  yearlyGet2MonthExtra: 'Yearly ( get 2 month extra)',
  basic: 'Basic',
  pro: 'Pro',
  advanced: 'Advanced',
  basicFree: 'Basic (Free)',
  startToBasic: 'Start To Basic',
  upgradeToPro: 'Upgrade To Pro.',
  upgradeToAdvance: 'Upgrade To Advance',
  comparePlans: 'Compare Plans',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  defaultInput: 'Default Input',
  activator: 'Activator',
  hover: 'Hover',
  menus: 'Menus',
  indeterminate: 'Indeterminate',
  sizeAndWidth: 'Size & Width',
  rotate: 'Rotate',
  determinate: 'Determinate',
  buffer: 'Buffer',
  radiosDefault: 'Radios Default',
  radiosDirectionRow: 'Radios Direction Row',
  radiosDirectionColumn: 'Radios Direction Column',
  radiosColors: 'Radios - Colors',
  switchesColors: 'Switches - Colors',
  continuous: 'Continuous',
  discrete: 'Discrete',
  customColors: 'Custom Colors',
  editableNumericValue: 'Editable numeric value',
  contextualSnackbar: 'Contextual snackbar',
  showSnackbar: 'Show Snackbar',
  centeredTabs: 'Centered Tabs',
  toolbarTabs: 'Toolbar Tabs',
  theDefaultColorToolbar: 'The Default Color Toolbar',
  appBar: 'App Bar',
  appBarWithExtension: 'App Bar With Extension',
  visibility: 'Visibility',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  toggle: 'Toggle',
  timePicker: 'Time Picker',
  timePickerInDialogAndMenu: 'Time Picker - In Dialog And Menu',
  itemsAndHeaders: 'Items And Headers',
  selectableRows: 'Selectable Rows',
  headerCell: 'Header Cell',
  expand: 'Expand',
  recentChat: 'Recent Chat',
  previousChats: 'Previous chats',
  addNewItems: 'Add New Items',
  addToDo: 'Add To Do',
  yes: 'Yes',
  ok: 'Ok',
  activeUsers: 'Active Users',
  addNew: 'Add New',
  readMore: 'Read More',
  assignNow: 'Assign Now',
  totalRequest: 'Total Request',
  new: 'New',
  pending: 'Pending',
  update: 'Update',
  updated10MinAgo: 'Updated 10 min ago',
  addNewCustomer: 'Add New Customer',
  name: 'Name',
  email: 'Email',
  editMember: 'Edit Member',
  scheduleDate: 'Schedule Date',
  title: 'Title',
  newEmails: 'New Emails',
  newEmail: 'New Email',
  employeePayroll: 'Employee Payroll',
  forMostOfTheUsers: 'For most of the users',
  choosePlan: 'Choose Plan',
  mega: 'Mega',
  master: 'Master',
  forDeveloper: 'For developer',
  forLargeEnterprises: 'For large enterprises',
  composeEmail: 'Compose Email',
  mailboxes: 'Mailboxes',
  folders: 'Folders',
  inbox5: 'Inbox (5)',
  draft: 'Draft',
  starred: 'Starred',
  sentMessages: 'Sent Messages',
  spam: 'Spam',
  delete: 'Delete',
  work: 'Work',
  personal: 'Personal',
  manageFolders: 'Manage Folders',
  contacts: 'Contacts',
  secretaries: 'Assistenten',
  settings: 'Instellingen',
  settingsPassengerAddress: 'Adres',
  settingsPassengerDressStyle: 'Kledingstijl',
  settingsPassengerSeatPosition: 'Jouw zitpositie',
  settingsPassengerDriverlabel: '',
  settingsPassengerCarInformation: '',
  settingsPassengerInformationForDriver: 'Informatie voor chauffeur',
  themeOptions: 'Theme Options',
  darkMode: 'Dark Mode',
  collapseSidebar: 'Collapse Sidebar',
  boxLayout: 'Box Layout',
  rtlLayout: 'Rtl Layout',
  backgroundImage: 'Background Image',
  sidebarFilters: 'Sidebar Filters',
  danger: 'Danger',
  primary: 'Primary',
  warning: 'Warning',
  success: 'Success',
  info: 'Info',
  dailySales: 'Daily Sales',
  trafficChannel: 'Traffic Channel',
  campaignPerformance: 'Campaign Performance',
  goToCampaign: 'Go To Campaign',
  fullScreen: 'Full Screen',
  ecommerce: 'E-Commerce',
  shop: 'Shop',
  cart: 'Cart',
  total: 'Total',
  totalPrice: 'Total Price',
  viewCart: 'View Cart',
  checkout: 'Checkout',
  apply: 'Apply',
  noItemsFound: 'No Items Found',
  billingDetails: 'Billing Details',
  placeOrder: 'Place Order',
  addNewCard: 'Add New Card',
  devicesShare: 'Devices Share',
  customerOverview: 'Customer Overview',
  weeklySales: 'Weekly Sales',
  recentOrders: 'Recent Orders',
  categorySales: 'Category Sales',
  webAnalytics: 'Web Analytics',
  topSelling: 'Top Selling',
  social: 'Social',
  newPost: 'New Post',
  publish: 'Publish',
  magazine: 'Magazine',
  editUser: 'Edit User',
  addNewUser: 'Add New User',
  dark: 'Dark',
  light: 'Light',
  recentUsers: 'Recent Users',
  activityAroundWorld: 'Activity Around World',
  adsIncome: 'Ads Income',
  recentComments: 'Recent Comments',
  recentNotifications: 'Recent Notifications',
  edit: 'Edit',
  topAuthors: 'Top Authors',
  marketingCampaign: 'Marketing Campaign',
  articles: 'Articles',
  websiteTraffic: 'Website Traffic',
  agency: 'Agency',
  recent: 'Recent',
  trending: 'Trending',
  totalPageViews: 'Total Page Views',
  impressions: 'Impressions',
  deviceSeparations: 'Device Separations',
  browserStatics: 'Browser Statics',
  totalDownloading: 'Total Downloading',
  notifications: 'Notifications',
  totalBookmarked: 'Total Bookmarked',
  itemsDownloads: 'Items Downloads',
  itemsUploaded: 'Items Uploaded',
  totalAnalytics: 'Total Analytics',
  hotKeywords: 'Hot Keywords',
  MaleUsers: 'Male Users',
  serverStatus: 'Server Status',
  purchaseVuely: 'Purchase Vuely',
  letsGetInTouch: 'Lets Get In Touch',
  maleUsers: 'Male Users',
  femaleUsers: 'Female Users',
  androidUsers: 'Android Users',
  iOSUsers: 'iOS Users',
  advance: 'Advance',
  routerAnimation: 'Router Animation',
  salesAndEarning: 'Sales And Earning',
  netProfit: 'Net Profit',
  totalExpences: 'Total Expences',
  photos: 'Photos',
  adCampaignPerfomance: 'Ad Campaign Perfomance',
  profitShare: 'Profit Share',
  topSellingTheme: 'Top Selling Theme',
  newRequest: 'New Request',
  followers: 'Followers',
  mailbox: 'Mailbox',
  sent: 'Sent',
  birthdays: 'Birthdays',
  events: 'Events',
  newsletter: 'Newsletter',
  lastMonth: 'Last Month',
  allTime: 'All Time',
  composeNewEmail: 'Compose New Email',
  activity: 'Activity',
  message: 'Message',
  selectTheme: 'Select Theme',
  continueShopping: 'Continue Shopping',
  proceedToCheckout: 'Proceed To Checkout',
  headerFilters: 'Header Filters',
  blog: 'Blog',
  signUp: 'Sign Up',
  login: 'Login',
  news: 'News',
  topHeadlines: 'Top Headlines',
  visitors: 'Visitors',
  subscribers: 'Subscribers',
  twitterFeeds: 'Twitter Feeds',
  extensions: 'Extensions',
  imageCropper: 'Image Cropper',
  videoPlayer: 'Video Player',
  dropzone: 'Dropzone',
  baseConfig: 'Base Config',
  audioTrackAndPlaysInline: 'Audio Track And Plays Inline',
  hlsLive: 'HLS Live',
  forgotPassword: 'Forgot Password',
  resetPassword: 'Reset Password',
  changePassword: 'Wachtwoord wijzigen',
  backToSignIn: 'Back To Sign In',
  enterYourEmailToSendYouAResetLink:
    'Enter your email to send you a reset link',
  pleaseEnterYourPasswordToReset: 'Please Enter Your Password To Reset',
  saas: 'SAAS',
  overallTrafficStatus: 'Overall Traffic Status',
  projectManagement: 'Project Management',
  projectTaskManagement: 'Project Task Management',
  totalSales: 'Total Sales',
  simple: 'Simple',
  transitions: 'Transitions',
  tax: 'Tax',
  expenses: 'Expenses',
  images: 'Images',
  ratings: 'Ratings',
  containAndCover: 'Contain And Cover',
  height: 'Height',
  fixedRatio: 'Fixed Ratio',
  gradients: 'Gradients',
  sizeVariants: 'Size Variants',
  newIcons: 'New Icons',
  webApplication: 'Web Application',
  person: 'Person',
  file: 'File',
  comment: 'Comment',
  form: 'Form',
  hardware: 'Hardware',
  directional: 'Directional',
  mapAliases: 'Map (Aliases)',
  dateAndTime: 'Date And Time',
  groups: 'Groups',
  buttonGroups: 'Button Groups',
  itemGroups: 'Item Groups',
  windows: 'Windows',
  onBoarding: 'On Boarding',
  accountCreation: 'Account Creation',
  treeview: 'Treeview',
  customSelectableIcons: 'Custom Selectable Icons',
  timelines: 'Timelines',
  usage: 'Usage',
  scopedSlots: 'Scoped Slots',
  asyncItems: 'Async Items',
  smallDots: 'Small Dots',
  iconDots: 'Icon Dots',
  coloredDots: 'Colored Dots',
  oppositeSlot: 'Opposite Slot',
  denseAlert: 'Dense Alert',
  fixedTabs: 'Fixed Tabs',
  rightAlignedTabs: 'Right Aligned Tabs',
  content: 'Content',
  withSearch: 'With Search',
  iconsAndText: 'Icons And Text',
  grow: 'Grow',
  pagination: 'Pagination',
  customIcons: 'Custom Icons',
  courses: 'Courses',
  coursesList: 'Courses List',
  courseDetail: 'Courses Detail',
  signIn: 'Sign In',
  payment: 'Payment',
  LearnWithYourConvenience: 'Learn With Your Convenience',
  management: 'Management',
  design: 'Design',
  development: 'Development',
  bestseller: 'BestSeller',
  addToCart: 'Add To Cart',
  popularInstructors: 'Popular Instructors',
  moreCoursesFromJamesColt: 'More Courses From James Colt',
  whatYoWillLearn: 'What you Will learn',
  description: 'Description',
  aboutInstructor: 'About Instructor',
  userSignIn: 'User Sign In',
  guestCheckout: 'Guest Checkout',
  continueAsGuest: 'Continue as Guest',
  paymentOptions: 'Payment Options',
  offerCode: 'Offer Code',
  enterCardDetails: 'Enter Card Details',
  makePayment: 'Make Payment',
  withContent: 'With Content',
  customHeight: 'Custom Height'
}
