<template>
  <div class="passenger-sidebar-wrapper">
    <v-btn
      class="btn-menu"
      @click.stop="drawer = !drawer"
    >
      <img
        src="../../../assets/icons/menu-icon.jpg"
        alt="Menu">
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
    >
      <v-list class="sidebar-user-info">
        <v-list-tile avatar>
          <v-avatar
            color="black"
            size="45">
            <img
              v-if="avatar"
              :src="avatar"
              alt="Avatar"
            >
            <v-icon
              v-else
              color="#fff">
              mdi-account-circle
            </v-icon>
          </v-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ user.firstName }} {{ user.lastName }}</v-list-tile-title>
            <router-link
              to="/account-settings/profile"
              class="secondaryGreen--text">Bewerk account</router-link>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <div
        v-if="$store.getters.getUser && $store.getters.getUser.secretaryOf && $store.getters.getUser.secretaryOf.length"
        class="secretary-passengers-select-wrapper">
        <secretary-passengers-select />
      </div>

      <v-container grid-list-xl>
        <div
          v-for="(category, key) in menus"
          :key="key">
          <ul class="list-unstyled">
            <li
              v-for="(item, index) in category"
              :key="index">
              <router-link :to="!item.exact ? `/${getCurrentAppLayoutHandler() + item.path}` : item.path">
                <v-icon
                  size="18"
                >{{ item.icon }}</v-icon>
                <span class="">{{ textTruncate($t(item.title)) }}</span>
                <v-badge
                  :value="item.name === 'rides' && $store.getters.getUser && $store.getters.getUser.chat && Boolean($store.getters.getUser.chat.unread)"
                  class="unread-messages-badge"
                  color="red">
                  <template v-slot:badge>
                    <span v-if="$store.getters.getUser.chat && parseInt($store.getters.getUser.chat.unread) > 99">!</span>
                    <span v-else-if="$store.getters.getUser.chat">{{ $store.getters.getUser.chat.unread }}</span>
                  </template>
                </v-badge>
              </router-link>
            </li>
          </ul>
        </div>

        <div>
          <a
            v-if="$store.getters.getUser.roles.includes('ROLE_DRIVER') && $store.getters.getEnv.envVersion.siteVersion !== 'ROLE_DRIVER'"
            class="logout"
            href="#"
            @click="changeEnvTo('driver')">
            <v-icon size="18">ti-exchange-vertical</v-icon>
            Driver
          </a>

          <a
            class="logout"
            href="#"
            @click="logOut()">
            <v-icon size="18">logout</v-icon>
            Uitloggen
          </a>
        </div>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { textTruncate, getCurrentAppLayout, isMobile } from '../../../helpers/helpers'
import SecretaryPassengersSelect from '../../passenger/secretaries/PassengersSelect.vue'

export default {
  name: 'SidebarPassengerRentABob',
  components: { SecretaryPassengersSelect },
  data () {
    return {
      drawer: null,
      avatar: null,
      user: {}
    }
  },
  computed: {
    ...mapGetters(['menus'])
  },
  mounted () {
    this.user = this.$store.getters.getUser
    this.drawer = !isMobile()
  },
  beforeMount () {
    if (this.$store.getters.getUser.passenger.photo) {
      this.avatar = this.$store.getters.getUser.passenger.photo.url
    }
  },
  methods: {
    changeEnvTo (env) {
      window.location.href = 'https://' + env + this.$store.getters.getEnv.envVersion.domains[this.$store.getters.getEnv.env]
    },
    textTruncate (text) {
      return textTruncate(text, 18)
    },
    getCurrentAppLayoutHandler () {
      return getCurrentAppLayout(this.$router)
    },
    async logOut () {
      const config = {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        withCredentials: true
      }

      const api = axios.create(config)
      localStorage.removeItem('isAuth')
      api.request({
        url: `${this.$store.getters.config.apiUrl}/token/kill`,
        method: 'post',
        data: {}
      })
      this.$store.dispatch('logoutUserFromFirebase', this.$router)
    }
  }
}
</script>

<style lang="scss" scoped>
  .secretary-passengers-select-wrapper {
    padding: 16px;
  }

  .passenger-sidebar-wrapper {
    aside {
      width: 223px !important;

      .sidebar-user-info {
        padding: 10px 0;
        border-bottom: 1px solid rgba(23, 24, 47, 0.1);

        .v-avatar {
          margin-right: 15px;
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul {
          margin-top: 10px;

          li {
            margin: 0 0 26px 0;

            a {
              color: #000;
              line-height: 21px;

              i {
                margin-right: 16px;
              }

              &.router-link-active {
                color: #83BB26 ;

                i {
                  color: #83BB26 ;
                }
              }
            }
          }
        }

        .logout {
          display: flex;
          align-items: center;
          color: #000;
          margin: 42px 0;

          i {
            margin-right: 16px;
          }
        }
      }

      .v-avatar {
        border: none;
        line-height: normal;
      }
    }
  }
</style>
