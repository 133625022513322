<!-- Header Structure -->
<template>
  <div>
    <v-navigation-drawer
      v-if="!horizontal"
      v-model="drawer"
      :mini-variant.sync="collapseSidebar"
      :width="250"
      :style="{backgroundImage: 'url(' + selectedSidebarBgImage.url + ')'}"
      :class="{'background-none': !backgroundImage}"
      :right="rtlLayout"
      app
      fixed
      mini-variant-width="70"
      class="Vuely-sidebar"
    >
      <!-- App Sidebar -->
      <app-sidebar/>
    </v-navigation-drawer>
    <v-toolbar
      v-if="$store.getters.showToolbar"
      :color="$store.getters.colorGuide.toolbar.headerColor"
      class="Vuely-toolbar"
      app
      fixed
    >
      <div class="d-custom-flex align-items-center navbar-left full-width">
        <div v-if="!horizontal">
          <v-toolbar-side-icon
            icon
            large
            dark
            class="v-step-0"
            @click.stop="drawer = !drawer"/>
        </div>
        <div
          v-if="global.getHeader != null"
          class="white-15 full-width row-layout-center mr-5 cap-first">
          {{ global.getHeader }}
        </div>
      </div>
      <div class="navbar-right">

        <user/>
      </div>
    </v-toolbar>
    <!-- Chat Searchbar -->
    <v-navigation-drawer
      v-model="chatSidebar"
      :right="!rtlLayout"
      fixed
      temporary
      app
      class="chat-sidebar-wrap">
      <chat-sidebar/>
    </v-navigation-drawer>
    <mobile-search-form/>
  </div>
</template>

<script>
import Sidebar from '../Sidebar/Sidebar.vue'
import ChatSidebar from '../ChatSidebar/ChatSidebar.vue'
import screenfull from 'screenfull'
import LanguageProvider from './LanguageProvider'
import Notifications from './Notifications'
import User from './User'
import Cart from './Cart'
import QuickLinks from './QuickLinks'
import MobileSearchForm from './MobileSearchForm'
import { getCurrentAppLayout } from 'Helpers/helpers'
import { mapGetters } from 'vuex'

export default {
  components: {
    appSidebar: Sidebar,
    ChatSidebar,
    LanguageProvider,
    Notifications,
    Cart,
    QuickLinks,
    MobileSearchForm,
    User
  },
  props: {
    horizontal: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      collapsed: false, // collapse sidebar
      drawer: null, // sidebar drawer default true
      chatSidebar: false, // chat component right sidebar
      sidebarImages: '', // sidebar background images
      enableDefaultSidebar: false
    }
  },
  computed: {
    ...mapGetters([
      'rtlLayout',
      'backgroundImage',
      'backgroundImage',
      'selectedSidebarBgImage',
      'darkMode',
      'collapseSidebar',
      'activeHeaderFilter'
    ])
  },
  watch: {
    '$route': {
      handler (to) {
        if (to.meta.header != null) this.$store.dispatch('setHeader', to.meta.header)
      },
      deep: true
    }
  },
  mounted () {
    if (this.$route.meta.header != null) this.$store.dispatch('setHeader', this.$route.meta.header)
  },
  methods: {
    // toggle full screen method
    toggleFullScreen () {
      if (screenfull.enabled) {
        screenfull.toggle()
      }
    },
    sidebarPath (link) {
      this.$store.dispatch('setActiveMenuGroup', { pathURL: link })
      this.$router.push(this.getMenuLink(link))
    },
    getMenuLink (link) {
      return '/' + getCurrentAppLayout(this.$router) + link
    },
    toggleSearchForm () {
      this.$store.dispatch('toggleSearchForm')
    }
  }
}
</script>
