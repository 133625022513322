/**
 * Vuely Global Components
 */
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppSectionLoader from 'Components/AppSectionLoader/AppSectionLoader'
import { RotateSquare2 } from 'vue-loading-spinner'

// Confirmation Dialog
import ConfirmationDialog from 'Components/ConfirmationDialog/ConfirmationDialog'

// delete Confirmation Dialog
import DeleteConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteConfirmationDialog'

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar'

// App Card component
import AppCard from 'Components/AppCard/AppCard'

// stats card
import StatsCard from 'Components/StatsCard/StatsCard'
import StatsCardV2 from 'Components/StatsCardV2/StatsCardV2'

// dialogs

import EditDialog from './components/DialogBox/EditDialog.vue'

// section tooltip
import SectionTooltip from 'Components/SectionTooltip/SectionTooltip'

// input hot keys
import InputHotKeyWrapper from 'Components/Customizer/InputHotKeyWrapper'

const GlobalComponents = {
  install (Vue) {
    Vue.component('appCard', AppCard)
    Vue.component('sectionTooltip', SectionTooltip)
    Vue.component('statsCard', StatsCard)
    Vue.component('statsCardV2', StatsCardV2)
    Vue.component('deleteConfirmationDialog', DeleteConfirmationDialog)
    Vue.component('confirmationDialog', ConfirmationDialog)
    Vue.component('vuePerfectScrollbar', VuePerfectScrollbar)
    Vue.component('appSectionLoader', AppSectionLoader)
    Vue.component('pageTitleBar', PageTitleBar)
    Vue.component('rotateSquare2', RotateSquare2)
    Vue.component('editDialog', EditDialog)
    Vue.component('InputHotKeyWrapper', InputHotKeyWrapper)
  }
}

export default GlobalComponents
